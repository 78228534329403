<template>
  <div class="editTeacher">
    <!-- 添加阅卷教师 -->
    <el-dialog
      :title="`添加${indexSubjectName} ${indexQuestion.questionNum}题 阅卷教师`"
      :visible.sync="teacherDialogVisible"
      :close-on-click-modal="false"
      width="1138px"
    >
      <div class="set-teacher-box">
        <div class="teacher-search-box">
          <div class="search-item">
            <!--  -->
            学校：
            <el-select
              v-model="modelSearch.schoolId"
              placeholder="请选择学段"
              :disabled="getSchoolDis()"
              filterable
              @change="selectChange"
            >
              <el-option label="全部学校" value=""> </el-option>
              <el-option
                v-for="item in schoolAllList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            学段：
            <el-select
              v-model="modelSearch.level"
              placeholder="请选择学段"
              clearable
              @change="levelChange"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            分类：
            <el-select
              v-model="modelSearch.type"
              placeholder="全部"
              @change="selectChange"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="item in typeOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>

          <div class="search-item">
            科目：
            <el-select
              v-model="modelSearch.subjectId"
              clearable
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in subjectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-input
            v-model="modelSearch.name"
            class="search-input"
            :class="{ 'school-list': examInformationPaper.examType == 1 }"
            placeholder="请输入搜索内容"
            @keyup.enter.native="schoolteacherlistv2()"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="schoolteacherlistv2()"
            >
            </i
          ></el-input>

          <div class="check-teacher-list">
            <div class="check-header">
              <template v-if="indexTeacherType != 4">
                <el-button type="text" @click="changeAll(1)"> 全选 </el-button>
                <el-button type="text" @click="changeAll(2)"> 取消 </el-button>
              </template>
              <template v-else>教师列表</template>
            </div>
            <div class="check-list edit-scroll-style">
              <el-checkbox-group
                v-model="checkTeacherList"
                @change="setMarkingList"
              >
                <el-tooltip
                  v-for="(v, i) in teacherList"
                  :key="i"
                  class="item"
                  effect="dark"
                  :content="v.username + ',' + v.schoolName"
                  placement="top"
                  :open-delay="500"
                >
                  <el-checkbox
                    :label="v.teacherId"
                    :disabled="getTeacherDis(v)"
                  >
                    {{ v.name }}({{ v.username }},{{ v.schoolName }})
                  </el-checkbox>
                </el-tooltip>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="teacher-list-box">
          <div class="teacher-list-header">
            <div>
              <template v-if="indexTeacherType == 1 || indexTeacherType == 2">
                <template v-if="indexQuestion.ruleScore != 1"
                  >一、二评</template
                >
                <template v-else> 单评</template>
                <span style="font-size: 12px; color: red; margin-left: 16px">
                  若教师数量大于任务量，最低会将老师的任务设为1
                </span>
              </template>

              <template v-if="indexTeacherType == 3">
                三评
                <span style="font-size: 12px; color: red; margin-left: 16px">
                  若教师数量大于任务量，最低会将老师的任务设为1
                </span>
              </template>
              <template v-if="indexTeacherType == 4"> 仲裁 </template>
            </div>
            <div>
              <span
                >已选用户
                <span style="color: #2474ed">
                  {{ markingTeacher.length }}
                </span>
                人</span
              >
              <el-button
                type="text"
                icon="el-icon-refresh-left"
                @click="clearAll()"
                >清空</el-button
              >
            </div>
          </div>
          <div class="teacher-cnt">
            <div>
              <template v-if="indexTeacherType != 4">
                批量设置任务量：<el-input
                  v-model="teacherNumber"
                  placeholder="请输入内容"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @blur="teacherNumber = $event.target.value"
                ></el-input>
                <el-button type="primary" @click="setTeacherCnt()"
                  >设置</el-button
                >
              </template>
            </div>
            <div class="teacher-btn-cnt">
              <template v-if="indexTeacherType != 4">
                <el-button type="primary" @click="showSet()">
                  平均分配任务量
                </el-button>
              </template>
            </div>
          </div>
          <div class="teacher-list-number-box edit-scroll-style">
            <!-- {{ markingTeacher }} -->
            <template v-for="(v, i) in markingTeacher">
              <div :key="i" class="teacher-item">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="v.schoolName + ',' + v.username"
                  placement="top"
                >
                  <span>{{ v.name }} </span>
                </el-tooltip>

                <el-input
                  v-if="indexTeacherType != 4"
                  v-model="v.workload"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  placeholder=""
                  @blur="v.workload = $event.target.value"
                ></el-input>
                <i
                  class="el-icon-delete"
                  style="margin-right: 4px"
                  @click="delTeacher(v.teacherId)"
                ></i>
                <template v-if="v.teacherHasMark">
                  已批阅：{{ v.teacherHasMark }}
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="teacherDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="submitTeacherList"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="平均分配任务量"
      :visible.sync="averageDialogVisible"
      width="466px"
      class="average-dialogVisible"
      :close-on-click-modal="false"
    >
      <div v-if="scoringMode == 0" style="margin-bottom: 12px">
        上传正常答题卡数量为
        <span class="nums">{{ sheetCount.sheetCount }}</span> 张，考生人数
        <span class="nums">{{ sheetCount.examStudentCount }} </span> 人
      </div>
      <div v-if="scoringMode == 1" style="margin-bottom: 12px">
        校任务量为
        <span class="nums">{{ indexSchoolData.workload }} </span> 份
      </div>
      <div>
        将
        <el-input
          v-model="averageVal"
          oninput="value=value.replace(/[^\d]/g,'')"
          placeholder="请输入任务量"
          @blur="averageVal = $event.target.value"
        ></el-input>
        张阅卷任务平均分配给所有教师！
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="averageDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setVal">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getSubjectList } from "@/core/util/util";
import { levelOptions } from "@/core/util/constdata";
import { schoolteachertype } from "@/core/api/exam/examTeacher";
import { schoollistbyexamidV2 } from "@/core/api/exam/examTeacher";
import { schoolteacherlistv2 } from "@/core/api/school/schoolteacher";
import {
  exammarkSaveTeacher,
  getSchoolWorkload,
  teacherSchoolList,
  groupWorkload,
} from "@/core/api/exam/examCorrectTask";
export default {
  name: "EditTeacher",
  data() {
    return {
      subjectOptions: [],
      // 教师列表弹出层 start
      teacherDialogVisible: false,
      averageDialogVisible: false,
      btnLoading: false,
      indexSubjectName: "",
      modelSearch: {
        level: "",
        subjectId: "",
        year: "",
        roleId: "",
        classNum: "",
        type: "",
        name: "",
        schoolId: "",
      },
      indexSchoolData: {},
      indexQuestion: {},
      examInformationPaper: {},
      typeOptions: [],
      teacherList: [],
      // 选中的教师列表
      checkTeacherList: [],
      scoringMode: 0,
      checkAllList: false,
      indexTeacherType: 1,
      teacherNumber: 0,
      averageVal: "",
      markingTeacher: [],
      schoolAllList: [],
      markingNum: { teacherId: "", workload: 0, type: 1 },
      // 教师列表弹出层 end
      levelOptions: levelOptions(),
      indexGroup: {},
      sheetCount: {},
    };
  },
  created() {
    this.subjectOptions = getSubjectList();
    this.getSchoolunionuserType();
    this.getSchoollistbyexamid();
  },
  methods: {
    async getTeacherSchoolList(val) {
      let data = {
        examPaperId: this.$route.query.paperId,
        schoolId: this.setSchoolId,
        sheetDetailId: val.sheetDetailId,
      };
      await teacherSchoolList(data)
        .then((res) => {
          this.indexQuestion = JSON.parse(JSON.stringify(val));
          this.indexQuestion.teacherList = res.data.data;
        })
        .catch(() => {});
    },
    setVal() {
      if (!this.averageVal) {
        this.$message({
          message: "请输入任务量",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let str = /^[0-9]*$/;
      if (!str.test(this.averageVal)) {
        this.$message({
          message: "只能输入数字",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.isHasMark = false;
      this.averageDialogVisible = false;
      this.averageTask(parseInt(this.averageVal));
    },
    // 根据搜索条件获取老师列表
    async schoolteacherlistv2() {
      this.modelSearch.groupId = "";
      if (this.indexGroup.id) {
        this.modelSearch.groupId = this.indexGroup.id;
      }
      if (this.modelSearch.schoolId) {
        delete this.modelSearch.groupId;
      }
      await schoolteacherlistv2(this.modelSearch).then((res) => {
        this.teacherList = res.data.data;
      });
    },
    async getSchoollistbyexamid() {
      const res1 = await schoollistbyexamidV2({
        examId: this.$route.query.examId,
        needUnit: true,
      });
      this.schoolAllList = res1.data.data;
    },
    async getSchoolunionuserType() {
      const res = await schoolteachertype({ examId: this.$route.query.examId });
      this.typeOptions = res.data.data;
    },
    // 学段修改
    levelChange() {
      this.subjectOptions = getSubjectList(this.modelSearch.level);
      let filterList = this.subjectOptions.filter(
        (item) => this.modelSearch.subjectId == item.subjectId
      );
      if (filterList.length == 0) {
        this.modelSearch.subjectId = "";
      }
      this.schoolteacherlistv2();
    },
    gradeChange() {
      this.schoolteacherlistv2();
    },
    selectChange() {
      this.schoolteacherlistv2();
    },
    getSchoolDis() {
      let type = false;
      if (this.$parent.scoringModeCopy == 1 && this.indexTeacherType < 4) {
        type = true;
      }
      if (
        this.$parent.scoringModeCopy == 2 &&
        this.indexGroup.scoringModeCopy == 1 &&
        this.indexTeacherType < 4
      ) {
        type = true;
      }
      if (
        this.$parent.scoringModeCopy != 0 &&
        (this.$parent.rollingMethod == 0 ||
          this.$parent.indexGroup.rollingMethod == 0) &&
        this.indexTeacherType == 3
      ) {
        type = false;
      }
      return type;
    },
    // 设置右侧列表数据
    setMarkingList() {
      this.markingTeacher.map((item) => {
        // 首先过滤取消了的试题
        if (this.checkTeacherList.indexOf(item.teacherId) == -1) {
          this.markingTeacher = this.markingTeacher.filter(
            (index) => index.teacherId != item.teacherId
          );
        }
      });
      // 添加列表中不存在的试题
      this.checkTeacherList.map((item) => {
        let type = true;
        this.markingTeacher.map((index) => {
          if (index.teacherId == item) {
            type = false;
          }
        });
        if (type) {
          let name = this.teacherList.filter(
            (index) => item == index.teacherId
          )[0].name;
          let data1 = this.teacherList.filter(
            (index) => item == index.teacherId
          )[0];
          let data = Object.assign({}, this.markingNum);
          data.teacherId = item;
          data.name = name;
          data.schoolName = data1.schoolName;
          data.username = data1.username;
          data.type = this.indexTeacherType;

          this.markingTeacher.push(data);
        }
      });
    },
    // 删除单个教师
    delTeacher(id) {
      let teacherName = "";
      this.markingTeacher.map((item) => {
        if (item.teacherId == id && item.teacherHasMark) {
          teacherName = item.name;
        }
      });
      if (teacherName) {
        this.$message({
          message: `${teacherName}老师已阅卷，不能删除`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.checkTeacherList = this.checkTeacherList.filter((i) => i != id);
      this.setMarkingList();
    },
    // 设置教师批阅数量
    setTeacherCnt() {
      if (this.markingTeacher.length == 0) {
        this.$message({
          message: "请先选择老师",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.markingTeacher.map((index) => {
        index.workload = this.teacherNumber;
      });
    },
    setTeacherWork() {
      let questionCount = "";

      if (this.scoringMode == 0) {
        let { sheetCount, examStudentCount } = this.sheetCount;
        sheetCount = Number(sheetCount);
        examStudentCount = Number(examStudentCount);
        questionCount = sheetCount || examStudentCount || 1;
      }
      if (this.scoringMode == 1) {
        questionCount = this.indexSchoolData.workload;
      }
      if (this.scoringMode == 2) {
        questionCount = this.indexGroupCnt[0].workload;
      }
      if (this.setSchoolId) {
        questionCount = this.checkSchool.workload;
      }
      let questionCount1 = "";
      // 如果是双评  任务量就应该*2
      if (this.indexQuestion.ruleScore != 1 && this.indexTeacherType == 1) {
        questionCount1 = questionCount * 2;
      } else {
        questionCount1 = questionCount;
      }
      this.markingTeacher = this.markingTeacher.map((item) => {
        if (!item.workload || item.workload == 0) {
          item.workload = questionCount1 || 1;
        }
        return item;
      });
    },
    // 平均分配
    averageTask(val) {
      if (this.markingTeacher.length == 0) {
        this.$message({
          message: "请先选择老师",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let questionCount = val;
      if (!val) {
        if (this.scoringMode == 0) {
          let { sheetCount, examStudentCount } = this.sheetCount;
          sheetCount = Number(sheetCount);
          examStudentCount = Number(examStudentCount);
          questionCount = sheetCount || examStudentCount || 1;
        }
        if (this.scoringMode == 1) {
          questionCount = this.indexSchoolData.workload;
        }
        if (this.scoringMode == 2) {
          questionCount = this.indexGroupCnt[0].workload;
        }
      }

      let questionCount1 = "";
      // 如果是双评  任务量就应该*2
      if (this.indexQuestion.ruleScore != 1 && this.indexTeacherType == 1) {
        questionCount1 = questionCount * 2;
      } else {
        questionCount1 = questionCount;
      }

      let teacherList = this.markingTeacher.length;

      this.markingTeacher.map((item) => {
        // 如果说本来就有老师情况  按最大值赋值
        // if (teacherHasMark.length > 0) {
        //   if (!item.workload) {
        //     item.workload = questionCount;
        //   }
        // } else {
        if (teacherList == 1) {
          item.workload = questionCount1;
          if (item.teacherHasMark > item.workload) {
            item.workload = item.teacherHasMark;
          }
          if (!item.workload || item.workload <= 0) {
            item.workload = 1;
          }
        } else {
          item.workload = parseInt(questionCount1 / teacherList);
          let workload = item.workload;

          if (!item.workload || item.workload <= 0) {
            item.workload = 1;
          }
          teacherList--;

          if (item.teacherHasMark > workload) {
            workload = item.teacherHasMark;
            item.workload = item.teacherHasMark;
          }
          questionCount1 = questionCount1 - workload;
        }

        // }
      });
    },
    // 全选  反选
    changeAll(type) {
      if (type == 1) {
        this.teacherList.forEach((item) => {
          if (this.checkTeacherList.indexOf(item.teacherId) == -1) {
            this.checkTeacherList.push(item.teacherId);
          }
        });
      } else {
        // console.log();
        let strArr = [];
        this.markingTeacher.forEach((item) => {
          if (item.teacherHasMark) {
            strArr.push(item.name);
          }
        });

        if (strArr.length > 0) {
          this.$message({
            message: `${strArr.join(",")}老师已阅卷，禁止取消全选！`,
            type: "warning",
            showClose: true,
          });
          return;
        }
        this.teacherList.forEach((item) => {
          this.checkTeacherList = this.checkTeacherList.filter(
            (index) => index != item.teacherId
          );
        });
      }
      // console.log(this.checkTeacherList);
      this.setMarkingList();
    },
    getTeacherDis(index) {
      // console.log(this.markingTeacher);
      let type = false;
      this.markingTeacher.map((item) => {
        if (item.teacherId == index.teacherId) {
          if (item.teacherHasMark > 0) {
            type = true;
          }
        }
      });
      return type;
    },
    showSet() {
      this.averageDialogVisible = true;

      if (this.scoringMode == 0) {
        let { sheetCount, examStudentCount } = this.sheetCount;
        sheetCount = Number(sheetCount);
        examStudentCount = Number(examStudentCount);
        this.averageVal = sheetCount || examStudentCount || 1;
      }
      if (this.scoringMode == 1) {
        this.averageVal = this.indexSchoolData.workload;
      }
      if (this.scoringMode == 2) {
        this.averageVal = this.indexGroupCnt[0].workload;
      }
    },
    // 保存老师任务
    submitTeacherList() {
      // 加个判断  如果是已经阅卷
      let arr = [];
      let array = [];
      // 存在出错的数据 已阅量低于任务量
      let teacherErr = [];

      // 是否已有老师阅卷  如果有  后边默认分配都给予老师最大值
      this.isHasMark = false;
      this.markingTeacher.map((index) => {
        if (!index.workload || index.workload == 0) {
          arr.push(index.name);
        }
        if (index.workload < 0) {
          array.push(index.name);
        }
        if (index.teacherHasMark > index.workload && index.type != 4) {
          teacherErr.push(index.name);
        }
        // if (index.teacherHasMark >0 && index.type == 4) {
        //   teacherError.push(index.name);
        // }
        if (index.teacherHasMark) {
          this.isHasMark = true;
        }
      });
      if (this.markingTeacher.length == 0) {
        this.$message({
          message: "至少设置一位老师！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (array.length > 0) {
        this.$message({
          message: "任务量不能设置为负数",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (teacherErr.length > 0) {
        this.$message({
          message: `${teacherErr.join(",")}老师已批阅量大于任务量，设置失败`,
          type: "warning",
          showClose: true,
        });
        return;
      }

      // 关闭的时候
      this.downType = false;

      // 判断下 如果每个老师都没设置任务量  就平均分配

      if (
        arr.length == this.markingTeacher.length &&
        this.indexTeacherType != 4
      ) {
        this.downType = true;
        this.averageTask();
      }
      // 如果部分老师设置了任务量  就将没有设置任务量的教师任务量设为最大值
      if (
        arr.length != this.markingTeacher.length &&
        this.indexTeacherType != 4
      ) {
        this.setTeacherWork();
      }
      let data = {
        markId: this.indexQuestion.id || "",
        examPaperId: this.examInformationPaper.paperId,
        sheetDetailId: this.indexQuestion.sheetDetailId,
        questionCount: this.indexQuestion.questionCount,
        schoolId: this.setSchoolId || this.schoolId || -1,
      };
      if (this.scoringMode == 2) {
        data.groupId = this.indexGroup.id;
      }
      if (data.schoolId != -1) {
        delete data.groupId;
      }

      let oldMarkingTeacher = this.indexQuestion.teacherList.filter(
        (item) => item.type != this.indexTeacherType
      );

      data.markingTeacher = oldMarkingTeacher.concat(this.markingTeacher);
      this.exammarkSaveTeacher(data);
    },
    exammarkSaveTeacher(data) {
      this.btnLoading = true;
      exammarkSaveTeacher(data)
        .then(() => {
          this.$parent.initView();
          this.teacherDialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 根据搜索条件获取老师列表
    async getSchoolWorkload(val) {
      let data = {
        schoolId: this.setSchoolId || this.schoolId,
        examPaperId: this.$route.query.paperId,
        sheetDetailId: val.sheetDetailId,
      };
      await getSchoolWorkload(data).then((res) => {
        if (res.data.data) {
          this.indexSchoolData = res.data.data;
        } else {
          this.indexSchoolData = {};
        }
      });
    },
    async initSchool(type, val, schoolItem) {
      this.setSchoolId = schoolItem.schoolId;
      this.checkSchool = schoolItem;
      this.modelSearch.schoolId = schoolItem.schoolId;
      await this.getTeacherSchoolList(val);
      if (this.scoringMode == 1) {
        await this.getSchoolWorkload(val);
        this.teacherNumber = this.indexSchoolData.workload;
        if (
          this.indexSchoolData.workload == undefined &&
          type != 4 &&
          this.schoolId
        ) {
          this.$message({
            showClose: true,
            message: "尚未设置校任务量，请设置后在添加阅卷教师",
            type: "warning",
          });
          return;
        }
        if (this.indexSchoolData.workload == 0 && type != 4 && this.schoolId) {
          this.$message({
            showClose: true,
            message: "校任务量为零，禁止设置修改添加教师",
            type: "warning",
          });
          return;
        }
        this.averageVal = this.indexSchoolData.workload;
      }
      this.markingTeacher = [];
      this.checkTeacherList = [];
      this.indexQuestion.teacherList.map((index) => {
        if (index.type == type) {
          this.checkTeacherList.push(index.teacherId);
          this.markingTeacher.push(index);
        }
      });
      this.indexTeacherType = type;
      this.teacherNumber = schoolItem.workload;
      this.teacherDialogVisible = true;
    },
    async initModel(type, val) {
      if (this.scoringMode == 1) {
        await this.getSchoolWorkload(val);

        if (
          this.indexSchoolData.workload == undefined &&
          type != 4 &&
          this.schoolId
        ) {
          this.$message({
            showClose: true,
            message: "尚未设置校任务量，请设置后在添加阅卷教师",
            type: "warning",
          });
          return;
        }
        if (this.indexSchoolData.workload == 0 && type != 4 && this.schoolId) {
          this.$message({
            showClose: true,
            message: "校任务量为零，禁止设置修改添加教师",
            type: "warning",
          });
          return;
        }

        this.averageVal = this.indexSchoolData.workload;
      }
      this.markingTeacher = [];
      this.checkTeacherList = [];
      this.indexQuestion = JSON.parse(JSON.stringify(val));
      this.indexQuestion.teacherList.map((index) => {
        if (index.type == type) {
          this.checkTeacherList.push(index.teacherId);
          this.markingTeacher.push(index);
        }
      });
      this.indexTeacherType = type;
      if (this.scoringMode == 0) {
        let { sheetCount, examStudentCount } = this.sheetCount;
        sheetCount = Number(sheetCount);
        examStudentCount = Number(examStudentCount);
        this.teacherNumber = sheetCount || examStudentCount || 1;
      }
      if (this.scoringMode == 1) {
        this.teacherNumber = this.indexSchoolData.workload;
      }
      if (this.scoringMode == 2) {
        await this.getGroupWorkload();
        this.teacherNumber = this.indexGroupCnt[0].workload;
      }
      this.teacherDialogVisible = true;
    },
    async getGroupWorkload() {
      let data = {
        examPaperId: this.$route.query.paperId,
        sheetDetailId: this.indexQuestion.sheetDetailId,
      };
      await groupWorkload(data).then((res) => {
        this.workList = res.data.data;
        // console.log(this.indexGroup);
        this.indexGroupCnt = res.data.data.filter(
          (item) => item.groupId == this.indexGroup.id
        );
      });
    },
    // 清空
    clearAll() {
      let strArr = [];
      this.markingTeacher.forEach((item) => {
        if (item.teacherHasMark) {
          strArr.push(item.name);
        }
      });

      if (strArr.length > 0) {
        this.$message({
          message: `${strArr.join(",")}老师已阅卷，禁止取消全选！`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.markingTeacher = [];
      this.checkTeacherList = [];
    },
    async init(type, val, schoolItem) {
      this.indexGroup = this.$parent.indexGroup;
      this.modelSearch.examPaperId = this.$route.query.paperId;
      this.examInformation = this.$parent.examInformation;
      this.modelSearch.level = this.examInformation.level.toString();
      this.modelSearch.year = this.examInformation.year;
      this.examInformationPaper = this.$parent.examInformationPaper;
      this.scoringMode = this.$parent.scoringMode;
      this.sheetCount = this.$parent.sheetCount;
      this.schoolId = this.$parent.schoolId;

      if (this.scoringMode == 1 || this.scoringMode == 2) {
        this.modelSearch.schoolId = this.schoolId;
      }
      // this.indexSchoolData = this.$parent.indexSchoolData;
      if (this.scoringMode == 2) {
        this.schoolAllList = this.$parent.schoolList;
      }
      this.examInformation.paperList.map((item) => {
        if (item.paperId == this.$route.query.paperId) {
          this.modelSearch.subjectId = item.subjectId;
          this.indexSubjectName = item.subjectName;
        }
      });
      this.setSchoolId = ""; // 获取教师列表
      if (schoolItem) {
        this.modelSearch.schoolId = schoolItem.schoolId;
      }
      await this.schoolteacherlistv2();
      if (schoolItem) {
        this.initSchool(type, val, schoolItem);
      } else {
        this.initModel(type, val);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.editTeacher {
  .average-dialogVisible {
    .el-input {
      width: 118px;
    }
    .nums {
      color: $primary-color;
    }
  }
  .set-teacher-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .teacher-list-box {
      width: 712px;
      border: 1px solid #dee1e7;
      height: 544px;
      overflow: hidden;
      .teacher-list-header {
        background: #f5f7fa;
        padding: 9px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-button {
          color: #f57574;
        }
      }
      .teacher-list-number-box {
        padding: 24px 12px 0 24px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        max-height: 424px;

        .teacher-item {
          // min-width: 160px;
          padding: 8px 12px;
          margin-right: 8px;
          border: 1px dashed #dee1e7;
          margin-bottom: 24px;
          letter-spacing: 0;
          i {
            cursor: pointer;
            color: #f57574;
            margin-left: 8px;
          }
          .el-input {
            width: 62px;
          }
        }
      }
      .teacher-cnt {
        display: flex;
        padding: 24px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dee1e7;

        .teacher-btn-cnt {
        }
        .el-input {
          width: 118px;
          margin-right: 24px;
        }
      }
    }
    .check-teacher-list {
      border: 1px solid #dee1e7;
      margin-top: 24px;
      width: 100%;
      .check-list {
        padding: 24px;
        height: 338px;
        overflow-y: scroll;
        .el-checkbox {
          display: block;
          margin-bottom: 18px;
        }
      }
      .el-checkbox {
        margin-bottom: 0;
      }
      .check-header {
        background: #f5f7fa;
        padding: 9px 24px;
      }
    }
    .teacher-search-box {
      width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .school-list {
        width: 48%;
        // margin-bottom: 24px;
      }
      .search-item {
        margin-bottom: 24px;
        .el-select {
          width: 118px;
        }
      }
    }
  }
}
</style>
