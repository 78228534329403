<template>
  <div class="exportFile">
    <el-dialog title="导出阅卷分工" :visible.sync="dialogVisible" width="512px">
      <div style="margin-bottom: 18px">
        请选择需要导出的学校：
        <el-select v-model="schoolId" placeholder="请选择">
          <el-option label="全部学校" value=""> </el-option>
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        请选择阅卷教师类型：
        <el-radio-group v-model="type">
          <el-radio
            v-for="(item, index) in typeList"
            :key="index"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="monitorBack()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fileDownloadByUrl } from "@/core/util/util";
import { exportUnionmark } from "@/core/api/exam/examCorrectTask";
export default {
  name: "ExportFile",
  components: {},
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      schoolList: [],
      type: 0,
      schoolId: "",
      typeList: [
        {
          label: "一二评",
          value: 0,
        },
        // {
        //   label: "三评教师",
        //   value: 1,
        // },
        {
          label: "仲裁",
          value: 2,
        },
      ],
    };
  },
  created() {},
  methods: {
    monitorBack() {
      let data = {
        examPaperId: this.$route.query.paperId,
        exportType: this.type,
        schoolId: this.schoolId,
        groupId: this.$parent.indexGroup.id,
      };
      this.btnLoading = true;
      exportUnionmark(data)
        .then((res) => {
          let { url, name } = res.data.data;
          this.$message({
            showClose: true,
            message: "已为您下载阅卷分工！",
            type: "success",
          });
          fileDownloadByUrl(url, name);
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init() {
      this.schoolList = this.$parent.schoolList;
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.exportFile {
  .el-radio {
    margin-bottom: 0;
  }
}
</style>
