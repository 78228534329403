<template>
  <div class="taskGroup">
    <el-dialog title="按组分配任务" :visible.sync="dialogVisible" width="780px">
      <div class="cnt-box">
        <div>
          <div class="question-box">
            <div class="question-header">待设置试题</div>
            <div class="question-list edit-scroll-style">
              <el-radio-group
                v-model="indexQuestion"
                @change="getGroupWorkload()"
              >
                <template v-for="(item, index) in exammarkList">
                  <template v-if="questionDis(item)">
                    <el-tooltip
                      :key="index"
                      class="item"
                      effect="dark"
                      :content="item.bindQuestionNum + '为绑定批阅'"
                      placement="top"
                      :open-delay="500"
                    >
                      <el-radio :label="item.sheetDetailId" disabled>
                        <template v-if="item.isOption == 1">选做题</template>
                        <template v-else>主观题</template>
                        {{ item.questionNum }}
                      </el-radio>
                    </el-tooltip>
                  </template>
                  <el-radio v-else :key="index" :label="item.sheetDetailId">
                    <template v-if="item.isOption == 1">选做题</template>
                    <template v-else>主观题</template>
                    {{ item.questionNum }}
                  </el-radio>
                </template>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div class="set-box">
          <div class="set-item">
            批量设置学校任务量：
            <el-radio-group v-model="taskType" @change="setTask()">
              <el-radio label="1"> 考生人数（数量）</el-radio>
              <el-radio label="2"> 正常卡（数量）</el-radio>
            </el-radio-group>
            <!-- <div style="margin-top: 18px">
              <el-button size="medium" type="primary" @click="setTask()">
                平均分配任务量
              </el-button>
            </div> -->
          </div>
          <div class="edit-scroll-style group-box">
            <div class="stu-nums">
              正常卡人数/考生人数： {{ sheetCount.sheetCount }}/{{
                sheetCount.examStudentCount
              }}
            </div>
            <div
              v-for="(item, index) in workList"
              :key="index"
              class="group-item"
            >
              <div>
                {{ item.groupName }}
                <el-input
                  v-model="item.workload"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  placeholder=""
                  @blur="item.workload = $event.target.value"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="medium"
          type="primary"
          :loading="btnLoading"
          @click="setQuestion(1)"
          >适用到所选题目</el-button
        >
        <el-button
          size="medium"
          type="primary"
          :loading="btnLoading"
          @click="setQuestion(2)"
          >适用到所有题目</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="平均分配任务量"
      :visible.sync="averageDialogVisible"
      width="466px"
      class="average-dialogVisible"
      :close-on-click-modal="false"
    >
      <div style="margin-bottom: 12px">
        上传正常答题卡数量为
        <span class="nums">{{ sheetCount.sheetCount }}</span> 张，考生人数
        <span class="nums">{{ sheetCount.examStudentCount }} </span> 人
      </div>
      <div>
        是否将
        <el-input
          v-model="averageVal"
          oninput="value=value.replace(/[^\d]/g,'')"
          placeholder="请输入任务量"
          @blur="averageVal = $event.target.value"
        ></el-input>
        张阅卷人物平均分配给所有分组？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="averageDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setVal">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  groupWorkload,
  saveGroupWorkload,
  exammarkUnionSheet,
} from "@/core/api/exam/examCorrectTask";

export default {
  name: "TaskGroup",
  components: {},
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      averageDialogVisible: false,
      tableData: [],
      checkList: [],
      workList: [],
      exammarkList: [],
      taskType: "1",
      stuNums: "",
      nums: "",
      averageVal: "",
      sheetCount: {},
      indexQuestion: "",
    };
  },
  created() {},
  methods: {
    questionDis(item) {
      let type = false;
      if (
        item.bindQuestionNum &&
        item.bindQuestionNum.split(",").indexOf(item.questionNum) != 0
      ) {
        type = true;
      }
      return type;
    },
    getGroupWorkload() {
      let data = {
        examPaperId: this.$route.query.paperId,
        sheetDetailId: this.indexQuestion,
      };
      groupWorkload(data).then((res) => {
        this.workList = res.data.data;
      });
    },
    init() {
      this.taskType = "";
      this.dialogVisible = true;
      this.exammarkList = this.$parent.exammarkList;
      this.indexQuestion = this.exammarkList[0].sheetDetailId;
      this.getGroupWorkload();
      this.exammarkUnionSheet();
    },
    // 获取当前考试所有任务量
    exammarkUnionSheet() {
      let data = {
        examPaperId: this.$route.query.paperId,
        schoolId: "",
      };

      exammarkUnionSheet(data)
        .then((res) => {
          this.sheetCount = res.data.data;
        })
        .catch(() => {});
    },
    setVal() {},
    saveGroupWorkload(arr) {
      this.dialogVisible = true;
      this.workList = this.workList.map((item) => {
        item.sheetDetailIds = arr;
        item.workload = item.workload || 0;
        return item;
      });
      this.btnLoading = true;
      saveGroupWorkload(this.workList)
        .then(() => {
          this.dialogVisible = false;
          this.btnLoading = false;
          this.$parent.initView();
          this.$message({
            showClose: true,
            message: "保存成功！",
            type: "success",
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    setQuestion(type) {
      if (type == 1) {
        let array = [this.indexQuestion];
        let arr = [];
        this.exammarkList.map((item) => {
          if (array.indexOf(item.sheetDetailId) != -1) {
            if (item.bindQuestionNum) {
              arr.push(item.sheetDetailId);
              this.exammarkList.map((index) => {
                if (item.bindQuestionNum.indexOf(index.questionNum) > 0) {
                  arr.push(index.sheetDetailId);
                }
              });
            } else {
              arr.push(item.sheetDetailId);
            }
          }
        });
        this.saveGroupWorkload(arr);
      } else {
        let arr = this.exammarkList.map((item) => item.sheetDetailId);
        this.saveGroupWorkload(arr);
      }
    },
    setTask() {
      // let nums =
      //   this.taskType == 1
      //     ? this.sheetCount.sheetCount
      //     : this.sheetCount.examStudentCount;
      // let groupList = this.workList.length;
      this.workList = this.workList.map((item) => {
        item.workload =
          this.taskType == 1 ? item.examStudentCount : item.normalAnswerSheet;
        return item;
        // examStudentCount
        // item.workload = parseInt(nums / groupList);
        // let workload = item.workload;
        // if (!item.workload || item.workload < 0) {
        //   item.workload = 1;
        // }
        // groupList--;
        // nums = nums - workload;
      });
    },
    showFp() {
      this.averageDialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.taskGroup {
  .average-dialogVisible {
    .el-input {
      width: 118px;
    }
    .nums {
      color: $primary-color;
    }
  }
  .cnt-box {
    display: flex;
    .stu-nums {
      margin-bottom: 18px;
      width: 100%;
    }
    .btn-box {
      margin: 18px auto;
      text-align: center;
    }
    .group-box {
      padding: 12px 18px;
      max-height: 280px;
      // display: flex;
      // flex-wrap: wrap;
      .group-item {
        margin-right: 24px;
        > div {
          border-radius: 4px;
          border: 1px solid #dee0e7;
          display: inline-block;
          padding: 8px 18px;
          margin-bottom: 18px;
        }

        .el-input {
          width: 104px;
        }
      }
    }
    .set-box {
      width: calc(100% - 258px);
      border-radius: 4px;
      border: 1px solid #dee0e7;
      //   > div {
      //     margin-bottom: 18px;
      //   }
      .set-item {
        padding: 18px;
        border-bottom: 1px solid #dee0e7;
        .el-radio {
          margin-left: 0;
          margin-right: 5px;
          margin-bottom: 0;
          ::v-deep {
            .el-radio__label {
              padding-left: 4px;
            }
          }
        }
        .el-input {
          width: 80px;
        }
      }
    }
    .question-box {
      width: 240px;
      border-radius: 4px;
      border: 1px solid #dee0e7;
      overflow: hidden;
      margin-right: 18px;
      .question-header {
        padding: 12px 18px;
        border-bottom: 1px solid #dee0e7;
        background: #f5f7fa;
      }
      .question-list {
        padding: 12px 18px;
        height: 340px;
        .el-radio {
          display: block;
          margin-bottom: 18px;
        }
      }
    }
  }
}
</style>
