<template>
  <div class="scoreRoleSet">
    <el-dialog
      title="评分规则设置"
      :close-on-click-modal="false"
      :visible.sync="scoreRolesdialogVisible"
      width="600px"
    >
      <div class="score-box">
        <div>题目名称： {{ indexQuestion.questionNum }}</div>
        <div>
          评分规则：
          <el-radio-group v-model="indexQuestion.ruleScore">
            <el-radio
              v-for="(v, i) in scoreRulesList"
              :key="i"
              :label="v.value"
              :disabled="setcheckTeacher(v)"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <template v-if="indexQuestion.ruleScore != 1">
          <div>
            误差分大于：
            <el-input
              v-model="indexQuestion.errorScore"
              style="width: 130px"
              placeholder="请输入分数"
              oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,2})?/g) ?? [""])[0]'
              @blur="indexQuestion.errorScore = $event.target.value"
            ></el-input>
            <span class="score"
              >(满分：{{ indexQuestion.questionScore }}分)</span
            >
          </div>
          <div>
            平均分计算规则：小数位数：
            <el-select
              v-model="indexQuestion.scaleType"
              placeholder="请选择"
              style="width: 130px; margin-right: 22px"
              @change="checkScale"
            >
              <el-option
                v-for="item in decimalDigits"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            进位方式：<el-select
              v-model="indexQuestion.carryType"
              placeholder="请选择"
              style="width: 130px"
            >
              <template v-for="item in digitsMethod">
                <el-option
                  v-if="$parent.getshow(item.value)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </template>
            </el-select>
          </div>
        </template>
        <p v-if="indexQuestion.ruleScore == 1" class="tips">
          <span>!</span
          ><span>【单评】</span
          >：一张试卷只由一个阅卷教师批阅，所给分数为最终分。
        </p>
        <p v-if="indexQuestion.ruleScore == 2" class="tips">
          <span>!</span
          ><span>【双评+仲裁】</span
          >：系统将同一考生的同一题目分发给两位不同的评卷教师（一评和二评），各自独立评分。
          在此之前，需要针对每道题目设置一个“误差分”，当一评二评分数之差在“误差分”范围内时，系统将自动取两人的平均分作为该题的最终得分。
          如果超出误差分范围，则由仲裁老师直接打分，作为该题的得分。
          <br />
          <br />
          <br />
          <span>【平均分计算规则】</span
          >：进位方式中取0.5就高、取0.5就低的规则：
          <br />
          • 取0.5就高：>0.5进位1，&lt;0.5变为0.5，举例：1.75⇒2,1.25⇒1.5
          <br />
          • 取0.5就低：>0.5变为0.5，&lt;0.5变为0，举例：1.75⇒1.5,1.25⇒1
        </p>
        <p v-if="indexQuestion.ruleScore == 3" class="tips">
          <span>!</span
          ><span>【2+1+仲裁】</span
          >系统将同一考生的同一题目分发给两位不同的评卷教师（一评和二评），各自独立评分。在此之前，需要针对每道题目设置一个“误差分”，当一评二评分数之差在“误差分”范围内时，系统将自动取两人的平均分作为该题的最终得分。如果超出误差分范围，系统将该考生答题信息随机分发给第三位评卷教师评分，称之“三评”，三评计分共分三种情形：
          <br />
          <br />
          <br />
          <br />
          •
          若三评打出的分数仅与一评或二评的分数差值在误差分范围内，系统自动取三评和一评或二评的平均分作为该题最终得分；
          <br />
          •
          若三评的评分与一评和二评的分数差值都在误差范围内，系统将取差值较小的两个分数的平均分作为最终得分，若差值相等则取三个分数中较高的两个分数的平均分；
          <br />
          •
          若三评的评分与一评或二评的分数差值均大于误差分，则由仲裁老师直接打分，作为该题的得分。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scoreRolesdialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitScore">
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { exammarkRuleScore } from "@/core/api/exam/examCorrectTask";
import { questionFilter } from "../questionFilter";
export default {
  name: "ScoreRoleSet",
  data() {
    return {
      scoreRolesdialogVisible: false,
      btnLoading: false,
      digitsMethod: [],
      decimalDigits: [],
      scoreRulesList: [],
      indexQuestion: {},
    };
  },
  created() {},
  methods: {
    setcheckTeacher(val) {
      let returnType = false;
      if (this.indexQuestion.hasMark == 1) {
        if (this.indexQuestion.ruleScore == 1) {
          if (val.value == 1) {
            returnType = false;
          }
          if (val.value == 2) {
            returnType = true;
          }
          if (val.value == 3) {
            returnType = true;
          }
        }
        if (this.indexQuestion.ruleScore == 2) {
          if (val.value == 1) {
            returnType = true;
          }
          if (val.value == 2) {
            returnType = false;
          }
          if (val.value == 3) {
            returnType = false;
          }
        }
        if (this.indexQuestion.ruleScore == 3) {
          if (val.value == 1) {
            returnType = true;
          }
          if (val.value == 2) {
            returnType = false;
          }
          if (val.value == 3) {
            returnType = false;
          }
        }
      }
      // if (val.value == 3 && this.indexQuestion.bindQuestionNum) {
      //   returnType = true;
      // }
      return returnType;
    },
    // 提交评分规则-设置给分方式
    submitScore() {
      let errorScore = false;
      let scaleType = false;
      let carryType = false;

      if (this.indexQuestion.ruleScore != 1) {
        if (
          !this.indexQuestion.errorScore &&
          this.indexQuestion.errorScore !== 0
        ) {
          errorScore = true;
        }
        if (!this.indexQuestion.scaleType) {
          scaleType = true;
        }
        if (!this.indexQuestion.carryType) {
          carryType = true;
        }
        // errorScoreArr = this.indexQuestion.errorScore.split(".");
      }

      if (errorScore) {
        this.$message({
          message: "请输入误差分！",
          type: "warning",
          showClose: true,
        });
        return;
      }

      if (this.indexQuestion.errorScore > this.indexQuestion.questionScore) {
        this.$message({
          message: "误差分不能大于小题总分！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (scaleType) {
        this.$message({
          message: "请选择小数位数！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (carryType) {
        this.$message({
          message: "请选择进位方式！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.exammarkRuleScore(1);
    },
    // 设置评分规则-设置给分方式
    exammarkRuleScore(type) {
      // type 1 评分规则 2 给分方式
      let data = {
        examPaperId: this.$route.query.paperId,
        type: type,
        ruleScore: [this.indexQuestion],
      };
      if (this.indexQuestion.bindQuestionNum) {
        data.ruleScore = questionFilter(
          this.exammarkList,
          this.indexQuestion,
          type
        );
      }

      this.btnLoading = true;
      exammarkRuleScore(data)
        .then(() => {
          this.$message({
            message: "修改成功！",
            type: "success",
            showClose: true,
          });
          this.scoreRolesdialogVisible = false;
          this.btnLoading = false;
          // 更新本地数据

          this.$parent.initView();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    checkScale() {
      if (this.indexQuestion.scaleType != 2) {
        if (
          this.indexQuestion.carryType == 4 ||
          this.indexQuestion.carryType == 5
        ) {
          this.indexQuestion.carryType = 3;
        }
      }
    },
    init() {
      this.indexQuestion = JSON.parse(
        JSON.stringify(this.$parent.indexQuestion)
      );
      this.oldIndexQuestion = JSON.parse(JSON.stringify(this.indexQuestion));
      this.exammarkList = this.$parent.exammarkList;
      this.scoreRulesList = this.$parent.scoreRulesList;
      this.decimalDigits = this.$parent.decimalDigits;
      this.digitsMethod = this.$parent.digitsMethod;

      this.scoreRolesdialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.scoreRoleSet {
  .score-box {
    .score {
      color: #ff0505;
      margin-left: 12px;
    }
    > div {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .score-title {
        width: 112px;
        text-align: right;
      }
    }
    .tips {
      padding: 12px;
      background: #fdf0ee;
      border: 1px dashed #ff7575;
      color: #777473;
      span {
        color: #e48469;
        margin-right: 4px;
        &:first-child {
          padding: 0px 6px;
          font-size: 12px;
          border-radius: 50%;
          background-color: #f17e5e;
          color: #ffffff;
          letter-spacing: 0;
        }
      }
    }
    .el-radio {
      margin-bottom: 0;
    }
  }
}
</style>
