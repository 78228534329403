export const questionFilter = (exammarkList, question, type) => {
  exammarkList = JSON.parse(JSON.stringify(exammarkList));
  let arr = [];
  arr.push(question);
  exammarkList.map((item) => {
    if (question.bindQuestionNum.indexOf(item.questionNum) != -1) {
      if (question.sheetDetailId != item.sheetDetailId) {
        if (
          item.ruleScore == question.ruleScore &&
          item.markStep == question.markStep
        ) {
          item.markTime = question.markTime;
          arr.push(item);
        } else {
          item.markStep = question.markStep;
          item.ruleScore = question.ruleScore;

          if (type == 2) {
            if (item.markStep == 1) {
              item.markScore = question.markScore;
            } else {
              let arr = [];
              let number = Number(item.questionScore);
              for (let i = 0; i <= number; i++) {
                arr.push(i);
              }
              // 判断下是否有余值

              if (item.questionScore % 1 > 0) {
                arr.push(item.questionScore);
              }

              item.markScore = arr.join(",");
              item.markTime = question.markTime;
            }
          } else {
            item.markTime = question.markTime;
            item.ruleScore = question.ruleScore;
            item.scaleType = question.scaleType;
            item.carryType = question.carryType;
          }

          arr.push(item);
        }
      }
    }
  });
  return arr;
};
