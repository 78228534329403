<template>
  <div class="importAllocation">
    <!-- 导入阅卷分工 -->
    <el-dialog title="导入阅卷分工" :visible.sync="dialogVisible" width="720px">
      <ElImportUpload
        ref="ElImportUpload"
        @upLoadSucc="upLoadSucc"
        @upLoadLoading="upLoadLoading"
      >
        <template slot="tips">
          <div class="tips">
            <div class="tips-title">【提示】</div>
            1、如果小题导入的教师与原有的教师相同，则会根据Excel内容修改任务量。<br />
            2、教师用户名为必填项，系统将通过用户名匹配教师。<br />
            3、教师用户名务必与阅卷教师列表一致。<br />
            4、若教师的已阅量大于分配的任务量，则将教师任务量设置为已阅量，若教师任务量为空，系统将自动分配任务量。<br />
            5、一行设置一个教师，如设置多个教师，则将小题复制多行，题目列不能为空。<br />
            6、绑定批阅的试题，须保证填写的各题教师用户名与任务量相同。<br />
            7、导入文件中，只要存在一条数据不合法，则所有数据都无法导入。<br />
          </div>
        </template>
        <template slot="down">
          <div class="down-box">
            <div>
              选择导入类型：
              <el-select
                v-model="type"
                placeholder="请选择"
                @change="checkType()"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <img src="@/assets/uExam/icon-down.png" alt="" />
              下载模版：
              <span class="down-tips" @click="downFile()">
                阅卷分工模板.xlsx
              </span>
            </div>
          </div>
        </template>
      </ElImportUpload>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="upLoadTeacher()"
        >
          导 入
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { exammarkExportUniontemplate } from "@/core/api/exam/examCorrectTask";
import { getStore } from "@/core/util/store";
import { fileDownloadByUrl } from "@/core/util/util";
import ElImportUpload from "@/components/ElImportUpload";
export default {
  name: "ImportAllocation",
  components: { ElImportUpload },
  data() {
    return {
      // 导入阅卷分工
      dialogVisible: false,
      btnLoading: false,

      examImportURL: "/exam/exammark/import/unionmark",
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      xlsData: {
        examPaperId: "",
      },
      type: 0,
      typeList: [
        {
          label: "一二评",
          value: 0,
        },
        {
          label: "三评教师",
          value: 1,
        },
        {
          label: "仲裁",
          value: 2,
        },
      ],
    };
  },
  created() {},
  methods: {
    upLoadTeacher() {
      this.btnLoading = true;
      this.$refs.ElImportUpload.submit();
    },
    upLoadLoading() {
      this.btnLoading = false;
    },
    checkType() {
      this.$refs.ElImportUpload.queryData = {
        examPaperId: this.$route.query.paperId,
        importType: this.type,
        schoolId: this.$parent.schoolId,
        groupId: this.$parent.indexGroup.id,
      };
    },
    upLoadSucc(data) {
      this.data = data;
      setTimeout(() => {
        // this.dialogVisible = false;
        this.$parent.initView();
      }, 2000);
    },
    init() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        let url = this.examImportURL;
        let accept = ".xlsx,.xls";

        this.$refs.ElImportUpload.queryData = {
          examPaperId: this.$route.query.paperId,
          importType: this.type,
          schoolId: this.$parent.schoolId,
          groupId: this.$parent.indexGroup.id,
        };
        this.$refs.ElImportUpload.init(url, accept);
      });
    },
    // 下载模板
    downFile() {
      let data = {
        examPaperId: this.$parent.examInformationPaper.paperId,
        importType: this.type,
      };
      exammarkExportUniontemplate(data).then((res) => {
        // console.log(res);
        fileDownloadByUrl(res.data.data.url, res.data.data.name);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.importAllocation {
}
</style>
