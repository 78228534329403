<template>
  <div class="scoreRole">
    <el-dialog title="多评规则" :visible.sync="dialogVisible" width="873px">
      <div class="rules-tips">
        <p>（一）多评流程：</p>
        <span>[单评]</span>.一张试卷只由一个阅卷教师批阅，所给分数为最终分。
        <br />
        <span>[双评+仲裁]</span
        >.系统将同一考生的同一题目分发给两位不同的评卷教师（一评和二评），各自独立评分。
        在此之前，需要针对每道题目设置一个“误差分”，当一评二评分数之差在“误差分”范围内时，系统将自动取两人的平均分作为该题的最终得分。
        如果超出误差分范围，则由仲裁老师直接打分，作为该题的得分。
        <br />
        <span>[2+1+仲裁]</span
        >.系统将同一考生的同一题目分发给两位不同的评卷教师（一评和二评），各自独立评分。
        <br />
        在此之前，需要针对每道题目设置一个“误差分”，当一评二评分数之差在“误差分”范围内时，系统将自动取两人的平均分作为该题的最终得分。
        如果超出误差分范围，系统将该考生答题信息随机分发给第三位评卷教师评分，称之“三评”，三评计分共分三种情形：
        <br />
        •
        若三评打出的分数仅与一评或二评的分数差值在误差分范围内，系统自动取三评和一评或二评的平均分作为该题最终得分；
        <br />
        •
        若三评的评分与一评和二评的分数差值都在误差范围内，系统将取差值较小的两个分数的平均分作为最终得分，若差值相等则取三个分数中较高的两个分数的平均分；
        <br />
        •
        若三评的评分与一评或二评的分数差值均大于误差分，则由仲裁老师直接打分，作为该题的得分。
        <p style="margin-top: 24px">
          （二）平均分计算规则： 进位方式中取0.5就高、取0.5就低的规则：
        </p>

        • 取0.5就高：>0.5进位1，＜0.5变为0.5，举例：1.75⇒2,1.25⇒1.5 <br />
        • 取0.5就低：>0.5变为0.5，＜0.5变为0，举例：1.75⇒1.5,1.25⇒1
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">我知道了</el-button>
        <!-- <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button> -->
      </span>
    </el-dialog>
    <!-- 评分规则设置 -->
  </div>
</template>
<script>
export default {
  name: "ScoreRole",
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {},
  methods: {
    init() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.scoreRole {
}
</style>
