<template>
  <div class="delVerify">
    <el-dialog title="温馨提示" :visible.sync="dialogVisible" width="400px">
      <div>
        确认删除
        <span style="color: #f57574">
          {{ schoolName }} {{ "主观题" + questionNum + "题" }}
        </span>
        的阅卷教师设置吗？删除后将无法恢复！确认清除请输入登录密码，否则点击取消：
      </div>
      <div class="password">
        <span>请输入登录密码：</span>
        <a-input-password
          v-model="password"
          placeholder=""
          autocomplete="new-password"
          @keyup.enter.native="monitorBack"
        />
      </div>
      <div style="margin-top: 12px; text-align: center">此操作不可撤销</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" :loading="btnLoading" @click="monitorBack()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  clearUnionteacher,
  singleteacher,
} from "@/core/api/exam/examCorrectTask";
import { encryption } from "@/core/util/util";
export default {
  name: "DelVerify",
  components: {},
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      password: "",
      schoolName: "",
      questionNum: "",
      showType: "",
    };
  },
  created() {},
  methods: {
    monitorBack() {
      if (!this.password) {
        this.$message({
          showClose: true,
          message: "请输入密码！",
          type: "warning",
        });
        return;
      }

      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = {
        examPaperId: this.$route.query.paperId,
        password: user.password,
        schoolId: this.schoolId,
      };
      if (this.scoringMode == 2) {
        data.groupId = this.indexGroup.id;
      }
      if (data.schoolId) {
        delete data.groupId;
      }
      // data.ids = this.dataList.map((item) => item.id).join(",");
      if (this.showType) {
        data.sheetDetailId = this.sheetDetailId;
        this.deleteQuestionItem(data);
      } else {
        this.deleteItem(data);
      }
    },

    deleteItem(data) {
      this.btnLoading = true;

      clearUnionteacher(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.$parent.initView();
          this.dialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    deleteQuestionItem(data) {
      this.btnLoading = true;

      singleteacher(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.$parent.initView();
          this.dialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init(row, type) {
      // type 0 清空全部 1 清空一道试题

      this.password = "";
      this.dialogVisible = true;
      // this.dataList = row;
      let { schoolId, schoolList, scoringMode, indexGroup } = this.$parent;
      this.schoolId = schoolId;
      this.scoringMode = scoringMode;
      this.indexGroup = indexGroup;
      schoolList.map((item) => {
        if (schoolId == item.schoolId) {
          this.schoolName = item.schoolName;
        }
      });
      if (!schoolId) {
        this.schoolName = "全部学校";
      }
      this.showType = type;
      if (type) {
        this.sheetDetailId = row.sheetDetailId;
        this.questionNum = row.questionNum;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.password {
  margin-top: 12px;
  display: flex;
  align-items: center;
  .ant-input-affix-wrapper {
    width: auto;
  }
  span {
    flex-shrink: 0;
  }
}
.delVerify {
}
</style>
