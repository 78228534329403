<template>
  <div class="scoreSet">
    <el-dialog
      title="给分方式设置"
      :visible.sync="scoreSetDialogVisible"
      width="586px"
    >
      <div class="score-set-box">
        <div class="tips">
          <span>!</span>
          注意：设置步长或自定义给分点可约束教师给分！
        </div>
        <div>
          <span class="score-title">题目名称：</span>
          {{ indexQuestion.questionNum }}
        </div>

        <div class="score-set-box-item">
          <span class="score-title">给分步长：</span>
          <el-radio-group
            v-model="indexQuestion.markStep"
            @change="markStepChange"
          >
            <el-radio v-for="(v, i) in stepSize" :key="i" :label="v.value">{{
              v.label
            }}</el-radio>
          </el-radio-group>
          <span class="score">(满分：{{ indexQuestion.questionScore }}分)</span>
        </div>
        <div>
          <span v-if="indexQuestion.markStep == 1" class="score-title">
            步长：
          </span>
          <span v-else class="score-title">给分点约束：</span>
          <el-input
            v-if="indexQuestion.markStep == 1"
            v-model="indexQuestion.markScore"
            placeholder="请输入"
            style="width: 200px"
            oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,2})?/g) ?? [""])[0]'
            @blur="indexQuestion.markScore = $event.target.value"
          ></el-input>
          <el-input
            v-if="indexQuestion.markStep == 2"
            v-model="indexQuestion.markScore"
            placeholder="请输入"
            style="width: 200px"
            oninput="value=value.replace(/[^0-9.,，]/g,'')"
            @blur="indexQuestion.markScore = $event.target.value"
          ></el-input>
        </div>
        <div>
          <span class="time-title">
            <el-tooltip class="item" effect="dark" content="" placement="top">
              <div slot="content">
                1.如阅卷时未达到最低阅卷时间，则不允许提交，避免盲目阅卷<br />2.支持一键开启过关闭现有设置，请返回批量设置操作
              </div>
              <span>!</span>
            </el-tooltip>

            最低阅卷时间：</span
          >
          <el-input
            v-model="indexQuestion.markTime"
            oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="请输入"
            style="width: 140px"
            @blur="indexQuestion.markTime = $event.target.value"
          >
          </el-input>
          秒
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scoreSetDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="submitScoreSet()"
        >
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { stepSize } from "@/core/util/constdata";
import { exammarkRuleScore } from "@/core/api/exam/examCorrectTask";
import { questionFilter } from "../questionFilter";
export default {
  name: "ScoreSet",
  data() {
    return {
      scoreSetDialogVisible: false,
      btnLoading: false,
      indexQuestion: {},
      stepSize: stepSize,
    };
  },
  created() {},
  methods: {
    // 步长类型切换
    markStepChange() {
      if (this.indexQuestion.markStep == 1) {
        if (this.oldIndexQuestion.markStep == 2) {
          this.indexQuestion.markScore = 1;
        } else {
          this.indexQuestion.markScore = this.oldIndexQuestion.markScore;
        }
      } else {
        let arr = [];
        let number = "";
        if (this.oldIndexQuestion.markStep == 1) {
          number = parseInt(
            this.indexQuestion.questionScore / this.oldIndexQuestion.markScore
          );
        } else {
          number = Number(this.indexQuestion.questionScore);
        }

        // console.log();
        for (let i = 0; i <= number; i++) {
          if (this.oldIndexQuestion.markStep == 1) {
            arr.push((i * (this.oldIndexQuestion.markScore * 10)) / 10);
          } else {
            arr.push(i);
          }
        }
        // 判断下是否有余值
        if (this.oldIndexQuestion.markStep == 1) {
          if (
            this.indexQuestion.questionScore % this.oldIndexQuestion.markScore >
            0
          ) {
            arr.push(this.indexQuestion.questionScore);
          }
        }

        this.indexQuestion.markScore = arr.join(",");
      }
    },
    // 设置给分方式
    submitScoreSet() {
      let type = false;
      let str = "";
      if (this.indexQuestion.markStep == 1) {
        if (this.indexQuestion.markScore > this.indexQuestion.questionScore) {
          type = true;
        }
        if (
          this.indexQuestion.markScore === 0 ||
          this.indexQuestion.markScore === "0"
        ) {
          type = true;
          str = "步长不能设置为0分！";
        }
      } else {
        this.indexQuestion.markScore = this.indexQuestion.markScore.replaceAll(
          "，",
          ","
        );
        let re = /^\d+(?=\.{0,1}\d+$|$)/;
        let markScoreList = this.indexQuestion.markScore.split(",");
        markScoreList.forEach((item) => {
          // console.log(re.test(item));
          if (!re.test(item)) {
            type = true;
          }
          if (item > this.indexQuestion.questionScore) {
            type = true;
          }
          if (item.split(".")[1] && item.split(".")[1].length > 2) {
            type = true;
          }
        });
      }
      if (type) {
        this.$message({
          message: str ? str : "步长无效",
          type: "warning",
          showClose: true,
        });
        return;
      }

      this.exammarkRuleScore(2);
    },
    // 设置评分规则-设置给分方式
    exammarkRuleScore(type) {
      // type 1 评分规则 2 给分方式
      let data = {
        examPaperId: this.$route.query.paperId,
        type: type,
        ruleScore: [this.indexQuestion],
      };
      if (this.indexQuestion.bindQuestionNum) {
        data.ruleScore = questionFilter(
          this.exammarkList,
          this.indexQuestion,
          type
        );
      }

      this.btnLoading = true;
      exammarkRuleScore(data)
        .then(() => {
          this.$message({
            message: "修改成功！",
            type: "success",
            showClose: true,
          });
          //   this[this.indexModel] = false;
          this.scoreSetDialogVisible = false;
          this.btnLoading = false;
          // 更新本地数据

          this.$parent.initView();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init() {
      this.indexQuestion = this.$parent.indexQuestion;
      this.oldIndexQuestion = JSON.parse(JSON.stringify(this.indexQuestion));
      this.exammarkList = this.$parent.exammarkList;
      this.scoreSetDialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.scoreSet {
  .score-set-box {
    .el-radio {
      margin-bottom: 0;
    }
    .score {
      color: #ff0505;
      margin-left: 12px;
    }
    .time-title {
      span {
        margin-right: 4px;
        padding: 0px 6px;
        font-size: 12px;
        border-radius: 50%;
        background-color: #2474ed;
        color: #ffffff;
        letter-spacing: 0;
      }
    }
    .tips {
      padding: 12px;
      background: #fdf0ee;
      border: 1px dashed #ff7575;
      color: #777473;

      span {
        color: #e48469;
        margin-right: 4px;
        &:first-child {
          padding: 0px 6px;
          font-size: 12px;
          border-radius: 50%;
          background-color: #f17e5e;
          color: #ffffff;
          letter-spacing: 0;
        }
      }
    }
    > div {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
