<template>
  <div class="ExamCorrectTask">
    <ExamDetailsHeader
      ref="ExamDetailsHeader"
      router-url="/exam/unionExam"
      :query="{ examId: $route.query.examId }"
      :name="examInformation.name + ' / '"
    >
    </ExamDetailsHeader>

    <div class="set-up-rules">
      <div class="rules-title rule-type">
        <div class="rules-left-box">
          上传正常卡人数/考生人数：
          <span class="span-err">
            {{ sheetCount.sheetCount }}
          </span>
          /{{ sheetCount.examStudentCount }}

          <el-button type="text" @click="exammarkExportOutMark()">
            <img src="@/assets/uExam/icon_export_a.png" alt="" />
            <img class="on" src="@/assets/uExam/icon_export_a.png" alt="" />
            导出分工
          </el-button>
          <el-button type="text" :disabled="!showType()" @click="showImport()">
            <template v-if="!showType()">
              <img src="@/assets/uExam/icon_export_a_un.png" alt="" />
              <img
                class="on"
                src="@/assets/uExam/icon_export_a_un.png"
                alt=""
              />
            </template>
            <template v-else>
              <img class="on" src="@/assets/uExam/icon_import_a.png" alt="" />
              <img src="@/assets/uExam/icon_import_a.png" alt="" />
            </template>

            导入分工
          </el-button>

          <el-button type="text" @click="$refs.scoreRole.init()">
            <i class="el-icon-warning"></i>
            多评规则
          </el-button>
          <template v-if="ifTaskAllocate">
            <span class="title-ms">阅卷模式：</span>
            <el-select
              v-model="scoringModeCopy"
              placeholder="请选择"
              @change="checkModel(1)"
            >
              <el-option
                v-for="item in taskTypeItem"
                :key="item.value"
                :disabled="item.disabled"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <template v-if="scoringMode == 2">
              <span class="title-ms">抽卷模式：</span>
              <el-select
                v-model="rollingMethod"
                placeholder="请选择"
                @change="setTaskType()"
              >
                <el-option label="全局抽卷" :value="0"> </el-option>
                <el-option label="组内抽卷" :value="1"> </el-option>
              </el-select>
              <el-tooltip
                class="item"
                effect="dark"
                content="注意: 所有学校需要完成分组后，方能进行其他阅卷设置"
                placement="top"
              >
                <el-button type="primary" plain @click="addGroup()">
                  添加分组
                </el-button>
              </el-tooltip>
              <el-button
                v-if="scoringMode == 2"
                type="primary"
                plain
                @click="setGroupTask()"
              >
                设置组任务量
              </el-button>
            </template>
          </template>
          <el-button
            v-if="indexGroup.scoringMode == 1 && scoringMode != 0"
            type="primary"
            plain
            @click="setSchoolTask()"
          >
            设置校任务量
          </el-button>
        </div>
        <div v-if="ifTaskAllocate" class="rules-left-box">
          <el-button
            type="text"
            :loading="exportLoding"
            @click="exammarkExportOutline()"
          >
            <img src="@/assets/uExam/icon_export_a.png" alt="" />
            <img class="on" src="@/assets/uExam/icon_export_a.png" alt="" />
            导出阅卷分工提纲
          </el-button>

          <el-tooltip
            class="item"
            effect="dark"
            content="是否允许一、二评教师抽到已批阅过的图片"
            placement="top"
          >
            <i class="el-icon-warning btn-tips"></i>
          </el-tooltip>

          允许重复批阅
          <el-switch
            v-model="allowRepeat"
            active-text=""
            :active-value="1"
            :inactive-value="0"
            inactive-text=""
            @change="putRepeat"
          >
          </el-switch>
        </div>
      </div>
      <div
        class="rules-title rule-type"
        style="margin-bottom: 0"
        :class="{ 'group-box': scoringMode == 2 }"
      >
        <div class="rules-left-box">
          <template v-if="scoringMode != 2">
            抽卷方式：
            <el-radio v-model="rollingMethod" :label="0" @change="setTaskType()"
              >全局随机抽卷</el-radio
            >
            <el-radio
              v-if="scoringMode != 0"
              v-model="rollingMethod"
              :label="1"
              @change="setTaskType()"
            >
              校内随机抽卷
            </el-radio>
          </template>
          <groupBox v-if="scoringMode == 2" ref="groupBox"></groupBox>
        </div>
        <div v-if="ifTaskAllocate" class="btn-list">
          <template v-if="scoringMode != 2">
            学校：
            <el-select
              v-model="schoolId"
              placeholder="请选择"
              filterable
              :disabled="listLoading"
              @change="setSchool()"
            >
              <el-option label="全部学校" value=""> </el-option>
              <el-option
                v-for="item in schoolList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </template>
          <template v-if="scoringMode == 1">
            <el-button
              v-if="scoringMode == 1"
              type="primary"
              plain
              @click="setSchoolTask()"
            >
              设置校任务量
            </el-button>
          </template>

          <el-button type="primary" plain @click="goBindQuestuion()">
            设置绑定批阅
          </el-button>
          <el-button type="primary" plain @click="setQuestuion()">
            设置小题组长
          </el-button>
          <el-button type="primary" plain @click="setScore()">
            设置分项给分
          </el-button>
          <el-button type="primary" plain @click="goChoose()">
            选做题处理
          </el-button>
          <el-button type="primary" plain @click="goSubject()">
            设置阅卷小科
          </el-button>
        </div>
      </div>
    </div>
    <div v-if="scoringMode == 2" class="group-check-box">
      <div class="group-left">
        <template>
          组抽卷分配：
          <el-select
            v-model="indexGroup.scoringModeCopy"
            placeholder="请选择"
            filterable
            @change="checkGroupScoring()"
          >
            <el-option label="按人分配" :value="0"> </el-option>
            <el-option label="按校分配" :value="1"> </el-option>
          </el-select>
          组抽卷模式：
          <template v-if="rollingMethod == 0">
            <el-select
              v-model="rollingMethod"
              placeholder="请选择"
              filterable
              disabled
              @change="setTaskGroupType()"
            >
              <el-option label="全局抽卷" :value="0"> </el-option>
            </el-select>
          </template>
          <template v-else>
            <el-select
              v-model="indexGroup.rollingMethod"
              placeholder="请选择"
              filterable
              :disabled="indexGroup.scoringModeCopy == 0"
              @change="setTaskGroupType()"
            >
              <el-option label="组内抽卷" :value="0"> </el-option>
              <el-option label="校内抽卷" :value="1"> </el-option>
            </el-select>
          </template>
        </template>
      </div>
      <div>
        学校：
        <el-select
          v-model="schoolId"
          placeholder="请选择"
          filterable
          style="margin-right: 0"
          :disabled="listLoading"
          @change="checkSchool()"
        >
          <el-option label="全部学校" value=""> </el-option>
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="tab-box">
      <el-table
        v-loading="listLoading"
        :data="exammarkList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="questionNum"
          label="题目名称"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <template
              v-if="
                scope.row.MarkInfoPoint && scope.row.MarkInfoPoint.length > 0
              "
            >
              <el-popover
                placement="right"
                trigger="click"
                class="el-popover-tips"
              >
                <div>
                  <p>分项给分</p>
                  <p v-for="(v, i) in scope.row.MarkInfoPoint" :key="i">
                    {{ v.name }}：{{ v.score }}分
                  </p>
                </div>
                <el-button slot="reference" type="text">
                  <template v-if="scope.row.isOption == 1">选做题</template>
                  <template v-else>主观题</template>{{ scope.row.questionNum }}
                </el-button>
              </el-popover>
            </template>
            <template v-else>
              <template v-if="scope.row.isOption == 1">选做题</template>
              <template v-else>主观题</template>{{ scope.row.questionNum }}
            </template>
          </template>
        </el-table-column>
        <el-table-column width="180" align="center">
          <template #header>
            <template v-if="scoringMode == 2">
              <el-tooltip
                class="item"
                effect="dark"
                content="完成设置后,所有分组同步生效"
                placement="top"
              >
                <span>评分规则</span>
              </el-tooltip>
            </template>
            <template v-else>评分规则</template>

            <el-button
              v-if="exammarkList.length > 0"
              class="set-btn"
              :disabled="getListDis()"
              plain
              icon="el-icon-setting"
              @click="setScoreRules(1)"
              >批量设置</el-button
            >
          </template>
          <template slot-scope="scope">
            <el-button
              type="text"
              class="set-list-btn"
              @click="showRoleSet(scope.row)"
            >
              {{ scope.row.ruleScore | setName(scoreRulesList) }}</el-button
            >

            <div v-if="scope.row.ruleScore != 1">
              误差分：{{ scope.row.errorScore }}分
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="229">
          <template #header>
            给分方式
            <i
              class="el-icon-question set-score"
              style="color: #2474ed"
              @click="scoreMethodDialogVisible = true"
            ></i>
            <el-button
              v-if="exammarkList.length > 0"
              class="set-btn"
              plain
              :disabled="getListDis()"
              icon="el-icon-setting"
              @click="setScoreRules(2)"
              >批量设置</el-button
            >
          </template>
          <template slot-scope="scope">
            <el-button
              type="text"
              class="set-list-btn"
              @click="setIndexQuestionRules(scope.row, 'scoreSetDialogVisible')"
            >
              {{ scope.row.markType | setName(scoreMethod)
              }}<template v-if="scope.row.markType == 3"
                >-{{ scope.row.pointType | setName(scoreTypeItem) }}</template
              >
            </el-button>

            <div
              v-if="
                scope.row.markStep == 1 &&
                scope.row.markScore &&
                scope.row.markType != 3
              "
            >
              步长：{{ scope.row.markScore }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="questionNum"
          label="已设置阅卷教师"
          align="center"
          class="tab-teacher-box"
        >
          <template slot-scope="scope">
            <template
              v-if="
                !scope.row.bindQuestionNum ||
                (scope.row.bindQuestionNum &&
                  scope.row.bindQuestionNum
                    .split(',')
                    .indexOf(scope.row.questionNum) == 0)
              "
            >
              <div class="tab-teacher-list-box">
                <div>
                  <div
                    slot="reference"
                    class="warning-box"
                    :class="{ 'end-num': getIndexClass(scope.row, 1) }"
                    @click="scope.row.visible = !scope.row.visible"
                  >
                    <template v-if="scope.row.ruleScore != 1">
                      一、二评
                    </template>
                    <template v-else
                      >{{ scope.row.ruleScore | setName(scoreRulesList) }}
                    </template>
                    (
                    <span class="null-num">{{
                      scope.row | setIndexTask(1)
                    }}</span>
                    / {{ scope.row | setTasks(1) }} )
                  </div>
                  <!-- 判断下没有任务 就是显示提示 -->

                  <template v-if="scope.row.workloadRemain > 0">
                    <!--  -->
                    <div v-if="showAllTips" class="task-box">
                      {{ `${scope.row.workloadRemain}份任务量未设置` }}
                    </div>
                  </template>
                  <!-- <template v-if="scoringMode == 0">
                    <div
                      v-if="
                        scope.row.teacherList &&
                        scope.row.teacherList.filter(
                          (item) => item.type == 1 || item.type == 2
                        ).length == 0 &&
                        showAllTips
                      "
                      class="task-box"
                    >
                      {{ scope.row | getCnt(1) }}
                    </div>
                  </template> -->
                  <template v-if="!schoolId">
                    <template v-for="(v, i) in scope.row.schoolList">
                      <span
                        v-if="v.type == 1"
                        :key="i"
                        class="teacher-list-name school-name"
                        @click="showTeacherSet(1, scope.row, v)"
                      >
                        {{ v.schoolName }} ({{ v.distribution }}/{{
                          v.workload
                        }})
                      </span>
                    </template>
                  </template>
                  <!-- <template v-if="rollingMethod == 1">222222222</template> -->
                  <template v-if="showType()">
                    <template v-for="(v, i) in scope.row.teacherList">
                      <el-tooltip
                        v-if="(v.type == 1 || v.type == 2) && getShowTeacher(v)"
                        :key="i"
                        class="item"
                        effect="dark"
                        :content="v.schoolName + ',' + v.username"
                        placement="top"
                      >
                        <span class="teacher-list-name">
                          {{ v.name }} ({{ v.workload }})
                        </span>
                      </el-tooltip>
                    </template>
                  </template>
                </div>
                <el-button
                  type="text"
                  :disabled="!showType()"
                  class="tab-set-btn"
                  @click="showTeacherList(1, scope.row)"
                >
                  设置
                </el-button>
              </div>
              <div v-if="scope.row.ruleScore == 3" class="tab-teacher-list-box">
                <div>
                  <div
                    class="warning-box"
                    plain
                    :class="{ 'end-num': getIndexClass(scope.row, 3) }"
                  >
                    三评(
                    <span class="null-num">{{
                      scope.row | setIndexTask(3)
                    }}</span>
                    / {{ scope.row | setTasks(3) }} )
                  </div>
                  <!-- <div
                    v-if="
                      scope.row.teacherList &&
                      scope.row.teacherList.filter((item) => item.type == 3)
                        .length == 0 &&
                      showAllTips
                    "
                    class="task-box"
                  >
                    {{ scope.row | getCnt(3) }}
                  </div> -->
                  <template v-if="showType(3)">
                    <template v-for="(v, i) in scope.row.teacherList">
                      <el-tooltip
                        v-if="v.type == 3 && getShowTeacher(v)"
                        :key="i"
                        class="item"
                        effect="dark"
                        :content="v.schoolName + ',' + v.username"
                        placement="top"
                      >
                        <span class="teacher-list-name">
                          {{ v.name }} ({{ v.workload }})
                        </span>
                      </el-tooltip>
                    </template>
                  </template>
                </div>
                <el-button
                  :disabled="!showType(3)"
                  type="text"
                  class="tab-set-btn"
                  @click="showTeacherList(3, scope.row)"
                >
                  设置
                </el-button>
              </div>
              <div v-if="scope.row.ruleScore != 1" class="tab-teacher-list-box">
                <div>
                  <div
                    class="warning-box"
                    :class="{
                      'end-num': !showErr(scope.row, 4),
                    }"
                  >
                    仲裁教师
                  </div>
                  <div v-if="showErr(scope.row, 4)" class="zc-task">
                    尚未设置仲裁老师
                  </div>
                  <template v-if="!schoolId">
                    <template v-for="(v, i) in scope.row.schoolList">
                      <span
                        v-if="v.type == 4"
                        :key="i"
                        class="teacher-list-name school-name"
                        @click="showTeacherSet(4, scope.row, v)"
                      >
                        {{ v.schoolName }}
                      </span>
                    </template>
                  </template>
                  <template v-if="showType(4)">
                    <template v-for="(v, i) in scope.row.teacherList">
                      <el-tooltip
                        v-if="v.type == 4"
                        :key="i"
                        class="item"
                        effect="dark"
                        :content="v.schoolName + ',' + v.username"
                        placement="top"
                      >
                        <span class="teacher-list-name">
                          {{ v.name }}
                        </span>
                      </el-tooltip>
                    </template>
                  </template>
                </div>
                <el-button
                  :disabled="!showType(4)"
                  type="text"
                  class="tab-set-btn"
                  @click="showTeacherList(4, scope.row)"
                  >设置</el-button
                >
              </div>
            </template>
            <template v-else>
              <template v-if="scope.row.bindQuestionNum">
                已和{{ scope.row.bindQuestionNum.split(",")[0] }}题设置绑定批阅
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column width="180" align="center">
          <template slot="header">
            操作
            <el-button
              v-if="showClear(0)"
              class="set-btn"
              plain
              icon="el-icon-setting"
              @click="clearTeacherList()"
              >清空阅卷老师</el-button
            >
          </template>
          <template slot-scope="scope">
            <template
              v-if="
                !scope.row.bindQuestionNum ||
                (scope.row.bindQuestionNum &&
                  scope.row.bindQuestionNum
                    .split(',')
                    .indexOf(scope.row.questionNum) == 0)
              "
            >
              <template v-if="scope.row.teacherList.length > 0">
                <el-button
                  type="text"
                  :disabled="!showType()"
                  @click="showCopyTeacher(scope.row)"
                >
                  复制
                </el-button>
                <el-button
                  v-if="showClear(scope.row)"
                  type="text"
                  class="btn-color"
                  @click="clearTeacherList(scope.row)"
                >
                  清空
                </el-button>
              </template>
            </template>
            <template v-else>
              <template v-if="ifTaskAllocate">
                <el-button
                  type="text"
                  :disabled="!showType()"
                  @click="goBindQuestuion()"
                >
                  预览
                </el-button>
                <el-button
                  type="text"
                  class="btn-color"
                  :disabled="!showType()"
                  @click="delQuestion(scope.row)"
                >
                  解绑
                </el-button>
              </template>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 查看给分方式 -->
    <el-dialog
      title="查看给分方式"
      :visible.sync="scoreMethodDialogVisible"
      width="30%"
      class="score-model"
    >
      <div>
        <!-- <p>计算给分</p>
        <img src="@/assets/imgs/exam/comImg.png" alt="" /> -->
        <p>一键给分</p>
        <img src="@/assets/imgs/exam/onekeyImg.png" alt="" />
      </div>
    </el-dialog>

    <scoreMethodModel ref="scoreMethodModel"></scoreMethodModel>
    <importAllocation ref="importAllocation"></importAllocation>
    <taskGroup ref="taskGroup"></taskGroup>
    <delVerify ref="delVerify"></delVerify>
    <exportFile ref="exportFile"></exportFile>
    <editPaperType ref="editPaperType"></editPaperType>
    <!-- 多评规则 -->
    <scoreRole ref="scoreRole"></scoreRole>
    <!-- 给分方式设置 -->
    <scoreSet ref="scoreSet"></scoreSet>
    <!-- 评分规则设置 -->
    <scoreRoleSet ref="scoreRoleSet"></scoreRoleSet>
    <!-- 复制阅卷老师 -->
    <copyTeacher ref="copyTeacher"></copyTeacher>
    <editTeacher ref="editTeacher"></editTeacher>
  </div>
</template>

<script>
import {
  exammarkTeacherList,
  schoolExamwork,
  exammarkExportOutline,
  exammarkMarkType,
  exammarkUnionSheet,
  exammarkExamRepeat,
  exampaperGroupMode,
  exampaperMode,
  groupValid,
  schoolGroupList,
  schoollistbygroupid,
} from "@/core/api/exam/examCorrectTask";
import {
  scoreRulesList,
  decimalDigits,
  digitsMethod,
  scoreMethod,
  scoreTypeItem,
} from "@/core/util/constdata";

import { exammarkBindquestionDel } from "@/core/api/exam/examBindQuestion";
import { fileDownloadByUrl, getYear } from "@/core/util/util";
import { getStore, removeStore, setStore } from "@/core/util/store";
import { getObj, schoollistbyexamid } from "@/core/api/exam/exam";
import ExamDetailsHeader from "@/components/examinationHeader";
import scoreMethodModel from "./components/scoreMethod";
import importAllocation from "./components/importAllocation";
import taskGroup from "./components/taskGroup";
import groupBox from "./components/groupBox";
import delVerify from "./components/delVerify";
import exportFile from "./components/exportFile";
import editPaperType from "./components/editPaperType";
import scoreRole from "./components/scoreRole";
import scoreSet from "./components/scoreSet";
import scoreRoleSet from "./components/scoreRoleSet";
import copyTeacher from "./components/copyTeacher";
import editTeacher from "./components/editTeacher";

// import { schoolunionuserType } from "@/core/api/cms/geo.js";
export default {
  name: "ExamCorrectTask",
  components: {
    ExamDetailsHeader,
    scoreMethodModel,
    importAllocation,
    taskGroup,
    groupBox,
    delVerify,
    exportFile,
    editPaperType,
    scoreRole,
    scoreSet,
    scoreRoleSet,
    copyTeacher,
    editTeacher,
  },
  filters: {
    // 获取当前类型总数
    setIndexTask(item, type) {
      let tasks = 0;
      item.teacherList.forEach((index) => {
        if (type == index.type) {
          tasks = Number(index.workload) + Number(tasks);
        }
      });
      return tasks;
    },
    // 获取任务总数
    setTasks(item) {
      let tasks = item.questionCount;

      // if (item.ruleScore != 1 && type == 1) {
      //   tasks = tasks * 2;
      // }
      return tasks;
    },
    getCnt(item, type, num) {
      let str = "任务量为空";
      // 获取已分配的任务量
      let tasks = 0;
      item.teacherList.forEach((index) => {
        if (type == index.type) {
          tasks = Number(index.workload) + Number(tasks);
        }
        if (type == 1 && index.type == 2) {
          tasks = Number(index.workload) + Number(tasks);
        }
      });
      // 获取任务总数
      let allTasks = item.questionCount;

      if (item.ruleScore != 1 && type == 1) {
        allTasks = allTasks * 2;
      }

      if (tasks < allTasks) {
        str = `尚有  ${allTasks - tasks} 任务量待分配`;
      }
      if (num) {
        str = allTasks - Number(item.getSchoolWorkload || 0);
      }

      return str;
    },
  },
  data() {
    return {
      scoringModeCopy: 0,
      ifTaskAllocate: false,
      scoringMode: 0,
      rollingMethod: 0,
      examInformation: {
        name: "",
      },
      allowRepeat: 0,
      indexQuestionNum: "",
      indexScaleType: 2,
      indexCarryType: 2,
      showAllTips: true,
      listLoading: false,
      yearList: getYear(),
      examInformationPaper: {},
      classOptions: [],
      scoreMethod: scoreMethod,
      scoreTypeItem: scoreTypeItem,
      // scoreRulesList: scoreRulesList.concat({ value: 4, label: "部分双评" }),
      scoreRulesList: scoreRulesList,
      digitsMethod: digitsMethod,
      decimalDigits: decimalDigits,
      taskType: 1,
      taskTypeItem: [
        {
          value: 0,
          label: "混合阅卷",
        },
        {
          value: 1,
          label: "单校阅卷",
        },
        {
          value: 2,
          label: "分组阅卷",
          // disabled: true,
        },
      ],

      dialogVisibleScore: false,
      // 按人分配
      exammarkList: [],
      // 当前试题
      indexQuestion: {},
      schoolId: "",
      btnLoading: false,
      // 给分方式
      scoreMethodDialogVisible: false,
      exportLoding: false,
      markTypeLoading: false,
      sheetCount: {
        examStudentCount: 0,
        sheetCount: 0,
      },
      paperList: [],
      schoolList: [],
      schoolAllList: [],
      groupList: [],
      roleId: "",
      indexGroup: {},
    };
  },
  created() {
    this.examInformationPaper = this.$route.query;
    this.indexId = new Date().getTime();

    this.getObj();
    setTimeout(() => {
      this.showAllTips = false;
    }, 10000);
    this.roleId = getStore({ name: "userInfo" }).roleId;
  },

  methods: {
    // 获取学校列表
    async getSchoollistbyexamid() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });
      this.schoolList = res.data.data;
    },
    // 根据分组获取学校列表
    async getSchoollistbygroupid() {
      const res = await schoollistbygroupid({
        examPaperId: this.$route.query.paperId,
        groupId: this.indexGroup.id,
      });
      // this.schoolId = "";

      this.schoolList = res.data.data;
      let arr = this.schoolList.filter(
        (item) => item.schoolId == this.schoolId
      );
      if (arr.length == 0) {
        this.schoolId = "";
      }
    },

    //联考-获取学校分组列表
    async getschoolGroupList() {
      let data = {
        examId: this.$route.query.examId,
        examPaperId: this.$route.query.paperId,
      };
      await schoolGroupList(data)
        .then((res) => {
          res.data.data = res.data.data.map((item) => {
            item.scoringModeCopy = item.scoringMode;
            return item;
          });

          this.groupList = res.data.data;
          if (this.groupList.length > 0) {
            if (this.indexGroup.id) {
              this.indexGroup = this.groupList.filter(
                (item) => item.id == this.indexGroup.id
              )[0];
            } else {
              this.indexGroup = this.groupList[0];
            }
          }
          if (this.groupList.length == 0) return;

          this.getSchoollistbygroupid();
        })
        .catch(() => {});
    },

    checkGroupScoring() {
      this.$refs.editPaperType.init(this.indexGroup);
    },
    checkModel() {
      this.$refs.editPaperType.init();
    },
    setTaskGroupType() {
      let data = {
        rollingMethod: this.indexGroup.rollingMethod,
        scoringMode: this.indexGroup.scoringModeCopy,
        examPaperId: this.examInformationPaper.paperId,
        groupId: this.indexGroup.id,
        ifChangeRolling: true,
      };
      exampaperGroupMode(data)
        .then(() => {
          // this.initView();
        })
        .catch(() => {
          this.indexGroup.rollingMethod =
            this.indexGroup.rollingMethod == 0 ? 1 : 0;
        });
    },
    setTaskType(type) {
      let data = {
        rollingMethod: this.rollingMethod,
        scoringMode: this.scoringModeCopy,
        examPaperId: this.examInformationPaper.paperId,
      };
      if (data.scoringMode == 0) {
        data.rollingMethod = 0;
      }
      if (!type) {
        data.ifChangeRolling = true;
      }

      exampaperMode(data)
        .then(() => {
          this.scoringMode = this.scoringModeCopy;
          this.rollingMethod = data.rollingMethod;
          this.examInformation.paperList.map((item) => {
            if (this.indexPaperId == item.paperId) {
              item.scoringMode = this.scoringMode;
              item.rollingMethod = this.rollingMethod;
            }
          });
          this.initView();
          if (type != 1) {
            this.setSchool();
            return;
          }
        })
        .catch(() => {
          this.examInformation.paperList.map((item) => {
            if (this.indexPaperId == item.paperId) {
              this.scoringMode = item.scoringMode || 0;
              this.rollingMethod = item.rollingMethod || 0;
            }
          });
        });
    },

    getIndexClass(item) {
      let returnType = true;
      if (item.workloadRemain > 0) {
        returnType = false;
      }
      return returnType;
    },
    upLoadTeacher() {
      this.$refs.uploadDemo.submit();
    },

    // 修改状态  是否允许重复批阅
    putRepeat() {
      let data = {
        examPaperId: this.examInformationPaper.paperId,
        repeat: this.allowRepeat,
      };
      const h = this.$createElement;
      let subjectName = this.paperList.filter(
        (item) => item.paperId == this.examInformationPaper.paperId
      )[0].subjectName;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, `将${subjectName}设为`),
          h(
            "span",
            { style: "color: red" },
            `${this.allowRepeat ? "允许" : "不允许"}`
          ),
          h("span", null, `重复批阅!`),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.exammarkExamRepeat(data);
        })
        .catch(() => {
          this.setallowRepeat();
        });
    },
    exammarkExamRepeat(data) {
      exammarkExamRepeat(data)
        .then(() => {
          this.paperList.map((item) => {
            if (item.paperId == this.examInformationPaper.paperId) {
              item.allowRepeat = this.allowRepeat;

              this.$message({
                showClose: true,
                message: `已将${item.subjectName}学科设为${
                  this.allowRepeat ? " 允许 " : " 不允许 "
                }重复批阅!`,
                type: "success",
              });
            }
          });
        })
        .catch(() => {
          this.setallowRepeat();
        });
    },
    setallowRepeat() {
      let indexPaper = this.paperList.filter(
        (item) => item.paperId == this.examInformationPaper.paperId
      );

      this.allowRepeat = indexPaper[0].allowRepeat;
    },
    initRole() {
      let indexPaper = this.paperList.filter(
        (item) => item.paperId == this.examInformationPaper.paperId
      )[0];
      let userInfo = getStore({ name: "userInfo" });
      this.ifTaskAllocate = true;
      let { ifTaskAllocate } = indexPaper;
      this.ifTaskAllocate = ifTaskAllocate == 1 ? false : true;
      if (
        this.examInformation.examUnionWorkList &&
        this.examInformation.examUnionWorkList.length > 0
      ) {
        this.examInformation.examUnionWorkList.forEach((item) => {
          if (item.subjectType == 3 && item.subjectId == indexPaper.subjectId) {
            if (item.teacherIds.indexOf(userInfo.teacherId) != -1) {
              this.ifTaskAllocate = true;
            }
          }
        });
      }
      if (userInfo.roleId != 5) {
        this.ifTaskAllocate = true;
      }
    },

    goTeacherList() {
      this.$router.push({
        name: "ExamTaskTeacherList",
        query: this.examInformationPaper,
      });
    },
    schoolGroup() {
      this.$router.push({
        name: "ExamTaskSchoolGroup",
        query: this.examInformationPaper,
      });
    },

    checkSubject(v) {
      if (this.listLoading) return;
      let data = JSON.parse(JSON.stringify(this.examInformationPaper));
      data.paperId = v.paperId;
      this.examInformationPaper.paperId = v.paperId;
      this.indexPaperId = v.paperId;
      this.indexGroup = {};
      data.time = new Date().getTime();
      clearTimeout(this.getNum);

      this.$router.push({
        // name: "unionExamCorrectTask",
        query: JSON.parse(JSON.stringify(data)),
      });
      this.$nextTick(() => {
        this.$refs.ExamDetailsHeader.init(this.indexPaperId);
      });
      this.paperList.map((item) => {
        if (this.indexPaperId == item.paperId) {
          this.scoringMode = item.scoringMode || 0;
          this.scoringModeCopy = this.scoringMode;
          this.rollingMethod = item.rollingMethod || 0;
        }
      });

      this.initView();
      this.setallowRepeat();
      this.indexId = new Date().getTime();
      if (this.scoringMode == 2) {
        this.getgroupValid();
      } else {
        this.exammarkUnionSheet(this.indexId);
      }
      clearTimeout(this.getNum);
    },
    setSchool() {
      clearTimeout(this.getNum);

      if (this.scoringModeCopy != 0) {
        this.initView();
        this.exammarkUnionSheet(this.indexId);
      }
    },
    checkSchool() {
      clearTimeout(this.getNum);
      this.exammarkTeacherList();
      this.exammarkUnionSheet(this.indexId);
    },
    getgroupValid() {
      let data = {
        examPaperId: this.indexPaperId,
      };
      groupValid(data).then((res) => {
        let { ifGroupFinished, ifSchoolCountChange } = res.data.data;
        if (ifGroupFinished) {
          this.$confirm("请先设置分组！", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            showCancelButton: false,
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
          })
            .then(() => {
              this.addGroup();
            })
            .catch(() => {});
          return;
        }
        if (ifSchoolCountChange) {
          this.$confirm(
            "参考学校数量发生变动，请及时调整各分组学校！",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              showCancelButton: false,
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
            }
          )
            .then(() => {
              this.addGroup();
            })
            .catch(() => {});
        }
      });
    },
    //获取考试基本信息
    getObj() {
      getObj(this.examInformationPaper.examId).then((res) => {
        this.examInformation = res.data.data;
        this.examInformationPaper.examType = res.data.data.examType;
        this.indexPaperId = this.examInformationPaper.paperId;
        //获取当前年级班级
        this.paperList = res.data.data.paperList.map((item) => {
          if (this.indexPaperId == item.paperId) {
            this.scoringMode = item.scoringMode || 0;
            this.scoringModeCopy = this.scoringMode;
            this.rollingMethod = item.rollingMethod || 0;
            this.initView();
            if (this.scoringMode == 2) {
              this.getgroupValid();
            } else {
              this.getSchoollistbyexamid();
              this.exammarkUnionSheet(this.indexId);
            }
          }
          if (item.statusSheet == 1 && item.sheetMarkType != 1) {
            item.label = item.subjectName;
            item.value = item.paperId;
            return item;
          } else {
            return null;
          }
        });

        this.paperList = this.paperList.filter((item) => item);
        this.setallowRepeat();
        this.initRole();

        this.$nextTick(() => {
          this.$refs.ExamDetailsHeader.init(this.indexPaperId);
        });
        // console.log(res);
      });
    },

    checkScaleType() {
      if (this.indexScaleType != 2) {
        if (this.indexCarryType == 4 || this.indexCarryType == 5) {
          this.indexCarryType = 3;
        }
      }
    },
    //判断是否显示选项
    getshow(val, Qtype) {
      let type = true;
      if (Qtype == 1) {
        if (this.indexScaleType != 2) {
          if (val == 4 || val == 5) {
            type = false;
          }
        }
      } else {
        if (this.indexQuestion.scaleType != 2) {
          if (val == 4 || val == 5) {
            type = false;
          }
        }
      }

      return type;
    },

    exammarkUnionSheet(id) {
      if (this.$route.name != "unionExamCorrectTask") {
        return;
      }
      if (this.indexId != id) {
        return;
      }
      let data = {
        examPaperId: this.examInformationPaper.paperId,
      };
      if (this.scoringModeCopy != 0) {
        data.schoolId = this.schoolId || "-1";
      } else {
        data.schoolId = "-1";
      }
      if (this.scoringModeCopy == 2) {
        data.groupId = this.indexGroup.id;
      }
      if (data.schoolId != -1) {
        data.groupId = "";
      }
      exammarkUnionSheet(data)
        .then((res) => {
          this.sheetCount = res.data.data;
          if (
            res.config.params.examPaperId != this.examInformationPaper.paperId
          ) {
            return;
          }
          // this.getNum = setTimeout(() => {
          //   this.exammarkUnionSheet(this.indexId);
          // }, 10000);
        })
        .catch(() => {
          // clearInterval(this.getNums);
        });
    },

    // 选做题页面
    goChoose() {
      this.$router.push({
        name: "ExamChooseQuestion",
        query: this.examInformationPaper,
      });
    },
    // 阅卷小科
    goSubject() {
      this.$router.push({
        name: "ExamMarkingSmallSubject",
        query: this.examInformationPaper,
      });
    },
    // 小题组长
    setQuestuion() {
      this.$router.push({
        name: "ExamQuestionLeader",
        query: this.examInformationPaper,
      });
    },
    // 绑定批阅
    goBindQuestuion() {
      this.$router.push({
        name: "unionExamBindQuestion",
        query: this.$route.query,
      });
    },
    // 设置校任务量
    setSchoolTask() {
      let data = {};
      if (this.scoringModeCopy == 2) {
        data.groupId = this.indexGroup.id;
      }
      this.$router.push({
        name: "ExamSchoolTask",
        query: Object.assign(data, this.$route.query),
      });
    },
    setGroupTask() {
      this.$refs.taskGroup.init();
    },
    // 分项给分
    setScore(type) {
      if (type != 1) {
        removeStore({
          name: "questionId",
          type: 1,
        });
      }
      removeStore({
        name: "exammarkPointItem",
        type: 1,
      });
      removeStore({
        name: "question",
        type: 1,
      });

      this.examInformationPaper.fromUrl = "unionExamCorrectTask";
      this.$router.push({
        name: "ExamTaskRules",
        query: this.examInformationPaper,
      });
    },
    // 切换考试分工类型
    exammarkMarkType() {
      let data = { examPaperId: this.examInformationPaper.paperId, type: 2 };
      this.markTypeLoading = true;

      let bindQuesiton = this.exammarkList.filter(
        (item) => item.bindQuestionNum
      );
      let fxQuestion = this.exammarkList.filter((item) => item.markType == 3);
      if (bindQuesiton.length > 0) {
        this.$alert("请删除绑定批阅后再切换为班级批阅", "温馨提示", {
          confirmButtonText: "确定",
          callback: () => {},
        });
        this.markTypeLoading = false;
        return;
      }
      if (fxQuestion.length > 0) {
        this.$alert("请删除分项给分后再切换为班级批阅", "温馨提示", {
          confirmButtonText: "确定",
          callback: () => {},
        });
        this.markTypeLoading = false;
        return;
      }
      exammarkMarkType(data)
        .then(() => {
          this.markTypeLoading = false;
          this.$message({
            type: "success",
            message: "切换成功!",
          });
          let data = Object.assign({}, this.examInformationPaper);

          this.$router.push({
            name: "ExamClassReadCorrectTask",
            query: data,
          });
        })
        .catch(() => {
          this.markTypeLoading = false;
        });
    },

    ExamSchoolReadCorrectTask() {
      this.$router.push({
        name: "ExamSchoolReadCorrectTask",
        query: this.examInformationPaper,
      });
    },
    setScoreRules(type) {
      this.examInformationPaper.type = type;
      this.examInformationPaper.fromUrl = this.$route.name;
      this.$router.push({
        name: "unionExamSetTaskRules",
        query: this.examInformationPaper,
      });
    },
    addGroup() {
      this.$router.push({
        name: "ExamTaskGroup",
        query: this.$route.query,
      });
    },
    // 导出阅卷分工
    exammarkExportOutline() {
      this.exportLoding = true;
      let data = {
        examPaperId: this.examInformationPaper.paperId,
      };
      exammarkExportOutline(data)
        .then((res) => {
          // console.log(res);
          fileDownloadByUrl(res.data.data.url, res.data.data.examPaperName);
          this.exportLoding = false;
        })
        .catch(() => {
          this.exportLoding = false;
        });
    },
    //导出阅卷分工提纲
    exammarkExportOutMark() {
      this.$refs.exportFile.init();
    },
    //评分规则
    showRoleSet(row) {
      this.indexQuestion = row;
      this.$refs.scoreRoleSet.init();
    },
    // 清除阅卷分工老师
    clearTeacherList(row) {
      let hasMarkArr = [];
      if (row) {
        if (row.hasMark) {
          hasMarkArr.push(row.questionNum);
        }
      } else {
        this.exammarkList.forEach((item) => {
          if (item.hasMark) {
            hasMarkArr.push(item.questionNum);
          }
        });
      }

      if (hasMarkArr.length > 0) {
        this.$message({
          message: `第${hasMarkArr.join(",")}已有老师阅卷，禁止清空！`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$refs.delVerify.init(row, row ? 1 : 0);
    },
    showCopyTeacher(item) {
      this.indexQuestion = item;
      this.$refs.copyTeacher.init();
    },
    showClear(item) {
      let type = true;

      if (item) {
        if (item.hasMark > 0) {
          type = false;
        }
        if (item.teacherList.length == 0) {
          type = false;
        }
      } else {
        let arr = [];
        this.exammarkList.forEach((index) => {
          if (index.hasMark) {
            type = false;
          }
          if (index.teacherList) {
            arr = arr.concat(index.teacherList);
          }
        });
        if (arr.length == 0) {
          type = false;
        }
      }
      // if (item === 0) {
      //   if (this.scoringMode == 1 && !this.schoolId) {
      //     type = false;
      //   }
      // }

      return type;
    },

    showErr(row, type) {
      let retType = false;
      if (
        row.teacherList &&
        row.teacherList.filter((item) => item.type == type).length == 0
      ) {
        retType = true;
      }
      if (this.rollingMethod == 1 && !this.schoolId) {
        if (
          row.schoolList &&
          row.schoolList.filter((item) => item.type == 4).length > 0
        ) {
          retType = false;
        }
      }
      return retType;
    },

    async showTeacherSet(type, val, schoolItem) {
      this.$refs.editTeacher.init(type, val, schoolItem);
    },
    //显示教师列表列表
    async showTeacherList(type, val) {
      this.$refs.editTeacher.init(type, val);
    },
    showImport() {
      this.$refs.importAllocation.init();
    },

    setIndexQuestionRules(question, type) {
      if (type == "scoreSetDialogVisible" && question.markType == 3) {
        if (question.hasMark) {
          this.$message({
            showClose: true,
            message: "已有老师阅卷，不可更改给分方式",
            type: "warning",
          });
          return;
        }
        setStore({
          name: "questionId",
          type: 1,
          content: question.sheetDetailId,
        });
        this.$refs.scoreMethodModel.init(question);
        return;
      }

      this.indexModel = type;

      this.indexQuestion = Object.assign({}, question);
      this.oldIndexQuestion = Object.assign({}, question);
      if (this.indexQuestion.carryType == 0) {
        this.indexQuestion.carryType = "";
      }
      if (this.indexQuestion.scaleType == 0) {
        this.indexQuestion.scaleType = "";
      }

      //  questionCheckList teacherTypeList
      this.teacherTypeList = [];
      this.questionCheckList = [];
      if (type == "scoreSetDialogVisible") {
        this.$refs.scoreSet.init();
        return;
      }
      this[type] = true;
    },
    // 获取学校列表

    schoolExamwork() {
      let data = {
        examPaperId: this.examInformationPaper.paperId,
        schoolId: this.schoolId,
      };
      if (this.$route.name == "unionExamCorrectTask") {
        data.schoolId = getStore({ name: "school_id" });
      }
      this.listLoading = true;
      schoolExamwork(data)
        .then((res) => {
          if (res.data.data.length == 0) {
            this.exammarkList = [];
          }
          // 添加两个显示任务量的弹窗
          res.data.data.map((item) => {
            item.visible = false;
            item.visible1 = false;
            item.markTime = item.markTime || 0;
            item.examMarkType = item.examMarkType || "";
          });
          // markTime

          this.exammarkList = res.data.data;
          // console.log(this.exammarkList);
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    getNewView() {
      this.getSchoollistbygroupid();
      this.indexId = new Date().getTime();
      this.exammarkUnionSheet(this.indexId);
      this.exammarkTeacherList();
    },
    async initView() {
      if (this.scoringModeCopy == 2) {
        await this.getschoolGroupList();
        this.exammarkUnionSheet(this.indexId);
      }
      this.exammarkTeacherList();
    },
    exammarkTeacherList() {
      let data = {
        examPaperId: this.$route.query.paperId,
      };
      if (this.scoringModeCopy) {
        data.schoolId = this.schoolId;
      }
      if (this.scoringModeCopy == 2) {
        data.groupId = this.indexGroup.id;
      }

      this.listLoading = true;

      exammarkTeacherList(data)
        .then((res) => {
          if (res.data.data.length > 0) {
            // 添加两个显示任务量的弹窗
            res.data.data.map((item) => {
              item.visible = false;
              item.visible1 = false;
              item.markTime = item.markTime || 0;
              item.useMarkTime = item.useMarkTime || 0;
              item.examMarkType = item.examMarkType || "";
            });
          }

          this.exammarkList = res.data.data;
          this.listLoading = false;
        })
        .catch(() => {
          // console.log(err);
          this.listLoading = false;
        });
    },
    // 获取试题是否已全部设置为分项给分
    getListDis() {
      let type = true;
      this.exammarkList.map((item) => {
        if (item.markType != 3) {
          type = false;
        }
      });
      return type;
    },
    showType(valType) {
      let type = false;
      if (this.scoringMode == 1 && this.schoolId) {
        type = true;
      }
      if (
        this.scoringMode == 2 &&
        this.indexGroup &&
        this.indexGroup.scoringMode == 0
      ) {
        type = true;
      }

      if (
        this.scoringMode == 2 &&
        this.indexGroup &&
        this.indexGroup.scoringMode == 1 &&
        this.schoolId
      ) {
        type = true;
      }
      if (this.scoringMode == 0) {
        type = true;
      }
      // if (
      //   valType == 3 &&
      //   this.scoringModeCopy != 0 &&
      //   this.rollingMethod == 0
      // ) {
      //   type = true;
      // }
      if (
        (valType == 4 || valType == 3) &&
        (this.rollingMethod == 0 || this.indexGroup.rollingMethod == 0)
      ) {
        type = true;
      }
      // if (valType == 4 && this.indexGroup.rollingMethod == 0) {
      //   type = true;
      // }

      return type;
    },
    getShowTeacher(row) {
      let type = true;

      if (
        this.scoringModeCopy == 0 &&
        this.schoolId &&
        this.schoolId != row.schoolId
      ) {
        type = false;
      }
      return type;
    },

    // 删除绑定批阅
    delQuestion(val) {
      this.$confirm(
        `此操作将解绑${val.bindQuestionNum}绑定的试题，是否确定。`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let data = {
            id: val.bindId,
          };
          exammarkBindquestionDel(data).then(() => {
            this.$message({
              type: "success",
              message: "解绑成功!",
            });
            this.initView();
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.el-popover {
  padding: 6px;
}
.ExamCorrectTask {
  .set-up-rules {
    .el-table__header {
      th {
        padding: 18px 0;
      }
    }
    .cell {
      padding: 0;
    }
  }
}
.type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px !important;
  .spotType {
    display: flex;
    align-items: center;
    i {
      color: #2474ed;
      margin-right: 4px;
    }
    .el-radio {
      margin-bottom: 0;
      margin-right: 24px;
    }
    .detail {
      text-decoration: underline;
      color: #a1a5ad;
      cursor: pointer;
    }
    .detail_active {
      text-decoration: underline;
      color: #2474ed;
      cursor: pointer;
    }
  }
  .taskType {
    display: flex;
    align-items: center;
    .el-radio {
      margin-bottom: 0;
      margin-right: 24px;
    }
    .detail {
      text-decoration: underline;
      color: #a1a5ad;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.ExamCorrectTask {
  padding: 24px 0 60px;
  .group-check-box {
    background-color: #ffffff;
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 18px;
    .el-select {
      width: 120px;
      margin-right: 18px;
    }
  }
  .group-box {
    border-bottom: 1px solid #dadadf;
    padding-bottom: 12px;
  }
  .btn-color {
    color: #f56c6b;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: $primary-color;
    font-size: 16px;
  }

  .score-model {
    p {
      margin: 24px 0;
      &:first-child {
        margin-top: 0;
      }
    }
    img {
      width: 100%;
    }
  }
  .upload-demo {
    ::v-deep .el-upload__text {
      line-height: 130px;
    }
    ::v-deep .el-upload {
      display: block;
    }
    ::v-deep .el-upload-dragger {
      width: 100%;
      height: 130px;
    }
  }
  .down-file {
    margin: 20px 0;
  }
  .examination-tips {
    background: #f2f8fe;
    border: 1px dashed #2474ed;
    padding: 13px 12px 0 36px;
    div {
      margin-bottom: 12px;
      &:first-child {
        position: relative;
        &::after {
          content: "!";
          letter-spacing: 0;
          position: absolute;
          left: -18px;
          top: 3px;
          width: 13px;
          height: 13px;
          background: #2474ed;
          border-radius: 50%;
          text-align: center;
          font-size: 12px;
          color: #ffffff;
          line-height: 12px;
        }
      }
    }
  }
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    color: #c0c4cc !important;
  }
  .task-num {
    .task-num-margin {
      margin: 24px 0;
    }
    .check-question-list {
      margin-top: 24px;
      .el-checkbox {
        margin-bottom: 12px;
      }
    }
    .el-radio {
      margin-bottom: 0;
    }
    .el-checkbox {
      margin-bottom: 0;
    }
  }

  .tab-teacher-list-box {
    border-top: 1px solid #ebeef5;
    padding: 6.5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .task-num {
      color: #fe5f59;
    }
    .zc-task {
      font-size: 12px;
      color: #fe5f59;
      margin-left: 12px;
      line-height: 28px;
    }
    .warning-box {
      border: 1px solid rgb(255, 190, 190);
      background-color: #ffffff;
      padding: 6px 10px;
      border-radius: 2px;
      color: #fe5f59 !important;
      line-height: 14px;
    }
    .task-box {
      border: 1px dashed #fe5f59;
      background: #fdf0ee;
      border-radius: 2px;
      color: #fe5f59;
      padding: 1px 8px;
      margin-left: 12px;
      position: relative;
      font-size: 12px;
      &::before {
        position: absolute;
        width: 10px;
        height: 10px;
        background: #fdf0ee;
        content: "";
        transform: rotate(45deg);
        -ms-transform: rotate(45deg); /* IE 9 */
        -moz-transform: rotate(45deg); /* Firefox */
        -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
        -o-transform: rotate(45deg);
        border: 1px dashed #fe5f59;
        left: -5px;
        top: 8px;
      }
      &::after {
        position: absolute;
        width: 7px;
        height: 16px;
        background: #fdf0ee;
        content: "";
        left: 0px;
        top: 5px;
      }
      // border: 1px solid #D8D8D8;;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      width: 95%;
    }
    .teacher-list-name {
      margin-left: 12px;
      line-height: 28px;
    }
    .school-name {
      color: #fe5f59;
      cursor: pointer;
    }
    .null-num {
      color: #ff0000;
    }
    .end-num {
      border: 1px solid #dddddd;
      background-color: #ffffff;
      color: #606266 !important;
      span {
        color: #606266 !important;
      }
    }
    .el-button.tab-set-btn {
      padding: 6px 0 2px;
    }

    .el-button {
      padding: 6px 10px;
    }

    &:first-child {
      border-top: none;
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  .rules-tips {
    color: #101011;
    line-height: 24px;
    p {
      font-weight: 500;
      color: #101011;
      margin-bottom: 24px;
    }
    span {
      color: $primary-color;
    }
  }
  .tab-box {
    background-color: #ffffff;
    padding: 18px;
    padding-top: 0;
  }
  .set-btn {
    padding: 5px 8px;
    display: block;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 4px;
    color: #9f9f9f;
    margin-top: 4px;
  }
  .set-up-rules {
    background-color: #ffffff;
    padding: 18px;
    margin-top: 18px;
    .set-score {
      // padding: 0 5px;
      color: $primary-color;
      border-radius: 50%;
      font-size: 16px;
      // color: #ffffff;
      margin-left: 4px;
      letter-spacing: 0;
      vertical-align: middle;
      cursor: pointer;
    }
    .set-list-btn {
      // text-decoration: underline;
      border-bottom: 1px solid $primary-color;
      padding-bottom: 3px;
      display: block;
      margin: 0 auto;
      // line-height: 16px;
    }
    .dis-btn {
      color: #dddddd;
      border-bottom: none;
    }

    .rule-type {
      margin-bottom: 18px;
    }
    .rules-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-select {
        width: 120px;
      }
      .btn-list {
        flex-shrink: 0;

        .el-select {
          margin-right: 12px;
        }
      }
      .rules-right-tips {
        color: $primary-color;
        font-size: 16px;
        cursor: pointer;
        border-bottom: 1px solid $primary-color;
        margin-left: 12px;
        span {
          padding: 0px 6px;
          letter-spacing: 0;
          background-color: #2474ed !important;
          border-radius: 50%;
          color: #ffffff;
          font-size: 12px;
          margin-right: 4px;
        }
      }
      .btn-tips {
        color: $primary-color;
        font-size: 16px;
        margin-left: 18px;
      }
      .rules-left-box {
        display: flex;
        align-items: center;

        .el-radio {
          margin-bottom: 0;
        }
        .el-switch {
          margin-left: 4px;
        }
        .title-ms {
          margin-left: 18px;
          font-size: 14px;
        }
        .el-button {
          margin-left: 18px;
          i {
            font-size: 16px;
          }
          img {
            vertical-align: bottom;
          }
        }
        em {
          color: $primary-color;
          font-style: normal;
          // margin-left: 25px;
          cursor: pointer;
          img {
            margin-right: 4px;
          }
        }
        .span-err {
          color: #ff0000;
        }
      }
    }
  }
  .header-box {
    background-color: #ffffff;
    padding: 24px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .el-select {
      width: 112px;
      height: 32px;
    }
    .el-input {
      width: 192px;
    }
    .header-search-box {
      font-weight: 400;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;

      .search-item {
        font-size: 14px;
        margin-right: 24px;
        margin-bottom: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .title {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
      color: #080a09;
      // width: 100%;
      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }
  // .el-dropdown {
  //   margin-right: 10px;
  // }
}
</style>
