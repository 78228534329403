<template>
  <div class="editPaperType">
    <el-dialog
      title="温馨提示"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="400px"
    >
      <div v-if="indexGroup.id">
        当前正在切换组内抽卷分配
        <span style="color: #f57574">
          {{ indexGroup.scoringModeCopy == 0 ? "按人分配" : "按校分配" }}
        </span>
        模式，切换后将清空已设置的阅卷教师信息！
      </div>
      <div v-else>
        当前正在切换阅卷模式为
        <span style="color: #f57574">
          {{ indexScoringModeCopy }}
        </span>
        模式，切换后将清空已设置的阅卷教师信息！
      </div>
      <div class="password">
        <span>请输入登录密码：</span>
        <a-input-password
          v-model="password"
          placeholder=""
          autocomplete="new-password"
          @keyup.enter.native="monitorBack"
        />
      </div>
      <div style="margin-top: 12px; text-align: center">此操作不可撤销</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="danger" :loading="btnLoading" @click="monitorBack()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  exampaperMode,
  exampaperGroupMode,
} from "@/core/api/exam/examCorrectTask";
import { encryption } from "@/core/util/util";
export default {
  name: "EditPaperType",
  components: {},
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      password: "",
      schoolName: "",
      questionNum: "",
      showType: "",
      indexScoringModeCopy: "",
      indexGroup: {},
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
      if (this.indexGroup.id) {
        this.$parent.indexGroup.scoringModeCopy = this.indexGroup.scoringMode;
      } else {
        this.$parent.scoringModeCopy = this.$parent.scoringMode;
      }
    },
    monitorBack() {
      if (!this.password) {
        this.$message({
          showClose: true,
          message: "请输入密码！",
          type: "warning",
        });
        return;
      }

      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });

      let data = {
        examPaperId: this.$route.query.paperId,
        password: user.password,
        scoringMode: this.scoringModeCopy,
        rollingMethod: this.$parent.rollingMethod,
        ifChangeRolling: false,
      };
      if (this.indexGroup.id) {
        this.checkModel(data);
        return;
      }
      if (data.scoringMode == 0) {
        data.rollingMethod = 0;
      }
      this.deleteItem(data);
    },
    checkModel(data) {
      data.scoringMode = this.indexGroup.scoringModeCopy;
      data.groupId = this.indexGroup.id;
      data.rollingMethod = this.indexGroup.rollingMethod;
      if (data.scoringMode == 0) {
        data.rollingMethod = 0;
      }
      this.btnLoading = true;
      exampaperGroupMode(data)
        .then(() => {
          if (data.scoringMode == 1) {
            this.$confirm(
              "按单校阅卷模式任务设置成功，请及时设置各校的任务量！",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                showCancelButton: false,
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
              }
            )
              .then(() => {
                this.$parent.setSchoolTask();
              })
              .catch(() => {});
          }
          this.$message({
            showClose: true,
            message: "切换成功",
            type: "success",
          });
          this.$parent.initView();

          this.dialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    deleteItem(data) {
      this.btnLoading = true;

      exampaperMode(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "切换成功",
            type: "success",
          });
          this.$parent.initView();

          this.dialogVisible = false;
          this.btnLoading = false;

          this.$parent.scoringMode = this.scoringModeCopy;
          this.$parent.rollingMethod = data.rollingMethod;
          this.$parent.examInformation.paperList.map((item) => {
            if (this.$route.query.paperId == item.paperId) {
              item.scoringMode = this.scoringModeCopy;
              item.rollingMethod = this.rollingMethod;
            }
          });
          if (data.scoringMode == 1) {
            this.$confirm(
              "按单校阅卷模式任务设置成功，请及时设置各校的任务量！",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                showCancelButton: false,
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
              }
            )
              .then(() => {
                this.$parent.setSchoolTask();
              })
              .catch(() => {});
          }
          if (data.scoringMode == 2) {
            this.$parent.getgroupValid();
          }
        })
        .catch(() => {
          this.btnLoading = false;
          this.$parent.examInformation.paperList.map((item) => {
            if (this.$route.query.paperId == item.paperId) {
              this.$parent.scoringMode = item.scoringMode || 0;
              this.$parent.rollingMethod = item.rollingMethod || 0;
            }
          });
        });
    },

    init(item) {
      this.indexGroup = {};
      if (item) {
        this.indexGroup = item;
      }
      this.password = "";
      this.dialogVisible = true;
      if (item) {
        //
      } else {
        let { scoringModeCopy } = this.$parent;
        this.scoringModeCopy = scoringModeCopy;
        let str = "";
        this.$parent.taskTypeItem.map((item) => {
          if (item.value == this.scoringModeCopy) {
            str = item.label;
          }
        });
        this.indexScoringModeCopy = str;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.password {
  margin-top: 12px;
  display: flex;
  align-items: center;
  .ant-input-affix-wrapper {
    width: auto;
  }
  span {
    flex-shrink: 0;
  }
}
.editPaperType {
}
</style>
