<template>
  <div class="copyTeacher">
    <el-dialog
      title="复制"
      :visible.sync="copyTeacherdialogVisible"
      width="650px"
    >
      <div class="model-teacher-box">
        <p>复制{{ indexQuestion.questionNum }}题到以下勾选的小题中</p>
        <!-- <el-checkbox-group v-model="checkList" @change="checkListChange"> -->
        <div class="model-teacher-box-btn">
          <el-button type="text" @click="checkListChange('checkAll')"
            >全选</el-button
          >
          <el-button type="text" @click="checkListChange('checkFalse')"
            >反选</el-button
          >
          <el-button type="text" @click="checkListChange('checkCancel')"
            >取消</el-button
          >
        </div>
        <div>
          <el-checkbox-group v-model="questionCheckList">
            <template v-for="(v, i) in exammarkList">
              <el-checkbox
                v-if="
                  indexQuestion.sheetDetailId != v.sheetDetailId &&
                  v.teacherList.length == 0
                "
                :key="i"
                :label="v.sheetDetailId"
                >{{ v.questionNum }}</el-checkbox
              >
            </template>
          </el-checkbox-group>
        </div>
        <div class="model-teacher-type">
          请选择需要复制的教师类型：
          <el-checkbox-group v-model="teacherTypeList">
            <template v-if="indexQuestion.ruleScore == 1">
              <el-checkbox :label="1">单评教师</el-checkbox>
            </template>
            <template v-else>
              <el-checkbox :label="1">一、二评教师</el-checkbox>
              <el-checkbox :label="4">仲裁教师</el-checkbox>
              <el-checkbox :label="3">三评教师</el-checkbox>
            </template>
            <!-- 1: 一、二评教师,2:仲裁教师,3:三评教师 -->
          </el-checkbox-group>
        </div>
        <div class="model-tips">
          <div>!</div>
          <span>注意</span>
          ：1.教师正在批阅中的试题，不允许勾选哦！2.复制需先清空原有设置后进行复制
          3.复制单评或一二评教师时，一组绑定批阅的试题需要全部勾选
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="copyTeacherdialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="exammarkCopyTeacher"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { exammarkCopyTeacher } from "@/core/api/exam/examCorrectTask";
export default {
  name: "CopyTeacher",
  data() {
    return {
      btnLoading: false,
      exammarkList: [],
      indexQuestion: {},
      // 复制教师 start
      copyTeacherdialogVisible: false,
      checkAll: false,
      checkFalse: false,
      checkCancel: false,
      questionCheckList: [],
      fileList: [],
      teacherTypeList: [],
      // 复制教师 end
    };
  },
  created() {},
  methods: {
    init() {
      this.exammarkList = this.$parent.exammarkList;
      this.indexQuestion = this.$parent.indexQuestion;
      this.questionCheckList = [];
      this.teacherTypeList = [];
      this.copyTeacherdialogVisible = true;
    },
    // 复制老师
    exammarkCopyTeacher() {
      if (this.questionCheckList.length == 0) {
        this.$message({
          message: "请先选择需要复制的试题题号",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.teacherTypeList.length == 0) {
        this.$message({
          message: "请先选择复制老师类型",
          type: "warning",
          showClose: true,
        });
        return;
      }

      let data = {
        copySheetDetailId: this.indexQuestion.sheetDetailId,
        examPaperId: this.$route.query.paperId,
        sheetDetailId: this.questionCheckList.join(","),
        type: this.teacherTypeList.join(","),
        schoolId: this.$parent.schoolId,
      };
      if (this.$parent.scoringMode == 2) {
        data.groupId = this.$parent.indexGroup.id;
      }
      if (data.schoolId) {
        delete data.groupId;
      }
      this.btnLoading = true;
      exammarkCopyTeacher(data)
        .then(() => {
          this.btnLoading = false;
          this.copyTeacherdialogVisible = false;
          this.$parent.initView();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 全选 反选 取消
    checkListChange(val) {
      if ("checkAll" == val) {
        let arr = [];
        this.exammarkList.forEach((item) => {
          if (
            item.sheetDetailId != this.indexQuestion.sheetDetailId &&
            item.teacherList.length == 0
          ) {
            arr.push(item.sheetDetailId);
          }
        });
        this.questionCheckList = arr;
      }

      if ("checkFalse" == val) {
        this.checkCancel = false;
        this.checkAll = false;
        let arr = [];
        this.exammarkList.forEach((item) => {
          if (
            this.questionCheckList.indexOf(item.sheetDetailId) == -1 &&
            item.teacherList.length == 0
          ) {
            arr.push(item.sheetDetailId);
          }
        });
        this.questionCheckList = arr;
      }
      if ("checkCancel" == val) {
        this.checkFalse = false;
        this.checkAll = false;
        this.questionCheckList = [];
      }
      // console.log(this.questionCheckList);
    },
  },
};
</script>
<style lang="scss" scoped>
.copyTeacher {
  .model-teacher-box {
    .model-teacher-box-btn {
      margin-bottom: 12px;
      .el-button {
        margin-right: 12px;
      }
    }
    .check-box {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-checkbox {
      margin-bottom: 24px;
      // min-width: 110px;
    }
    .model-teacher-type {
      display: flex;
      // margin: 12p 0;
    }
    .model-tips {
      background: #fdf0ee;
      border: 1px dashed #ff7575;
      color: #777473;
      padding: 12px 32px;
      position: relative;
      div {
        position: absolute;
        left: 13px;
        top: 16px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        display: inline-block;
        background: #f17e5e;
        letter-spacing: 0;
        font-size: 12px;
        text-align: center;
        line-height: 13px;
        color: #ffffff;
        margin-right: 6px;
      }
      span {
        color: #e48469;
      }
    }
  }
}
</style>
