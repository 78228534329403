import { render, staticRenderFns } from "./ExamCorrectTask.vue?vue&type=template&id=147cd14e&scoped=true&"
import script from "./ExamCorrectTask.vue?vue&type=script&lang=js&"
export * from "./ExamCorrectTask.vue?vue&type=script&lang=js&"
import style0 from "./ExamCorrectTask.vue?vue&type=style&index=0&id=147cd14e&prod&lang=scss&"
import style1 from "./ExamCorrectTask.vue?vue&type=style&index=1&id=147cd14e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147cd14e",
  null
  
)

export default component.exports